import { Injectable, OnDestroy } from '@angular/core';
import { VariableDto } from '../api/api.client';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VariableStorageService implements OnDestroy {
  public readonly variables$: BehaviorSubject<Array<VariableDto>> =
    new BehaviorSubject(this.getVariables());

  constructor() {}

  ngOnDestroy(): void {
      this.variables$.complete();
  }

  setVariables(variables: Array<VariableDto>): void {
    localStorage.setItem('variables', JSON.stringify(variables));
    this.variables$.next(variables);
  }

  getVariables(): Array<VariableDto> {
    const variables = JSON.parse(localStorage.getItem('variables') as string);

    if (variables) {
      return variables;
    }
    return [];
  }
}
