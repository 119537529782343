import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { FormlyModule } from '@ngx-formly/core';

@Component({
  selector: 'app-import-image',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    MaterialFileInputModule,
    MatFormFieldModule,
    MatDialogModule, 
    MatButtonModule,
    MatIconModule,
    FormlyModule
  ],
  templateUrl: './import-image.component.html',
  styleUrls: ['./import-image.component.scss']
})
export class ImportImageComponent {
  @ViewChild('fileRef') fileRef: ElementRef;

  form: FormGroup = this.fb.group({
    images: this.fb.array([])
  });

  get images() {
    return this.form.get('images') as FormArray;
  }

  constructor(
    private fb: FormBuilder, 
    private dialogRef: MatDialogRef<ImportImageComponent>,
    @Inject(MAT_DIALOG_DATA) data: { imageBase64Urls: string[] }
  ) {
    data.imageBase64Urls?.forEach((base64Url: string) => {
      this.addBase64Url(base64Url);
    });
  }

  async addImage(event: Event) {
    const element = event.currentTarget as HTMLInputElement;
    let fileList: FileList | [] = element.files ?? [];
    Array.from(fileList).forEach(async (file: File) => { 
        this.addBase64Url(await this.getDataURL(file));
    });
    this.fileRef.nativeElement.value = '';
  }

  addBase64Url(base64Url: any) {
    const formGroup =  this.fb.group({fileData: [base64Url]});
    this.images.push(formGroup); 
  }
  
  removeImage(index: number) {
    this.images.removeAt(index);
  }

  onDismiss() {
    this.dialogRef.close();
  }

  onConfirm() {
    const imageBase64Urls = [];
    for (let image of this.images.controls) {
      imageBase64Urls.push(image.get('fileData')?.value);
    }
    this.dialogRef.close(imageBase64Urls);
  }

  private getDataURL(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
  }
}
