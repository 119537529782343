<mat-sidenav-container fullscreen>
  <!--<mat-sidenav #snav mode="side" [opened]="(loggedIn$ | async) && screenWidth > sidenavBreakpoint">-->
  <mat-sidenav #snav mode="side" [opened]="(loggedIn$ | async) && runningCategoryType === CATEGORY_NONE">

    <mat-nav-list *ngIf="role$ | async as role">
      <mat-list-item class="loggedIn">
        <img src="assets/icon.svg">
        <ng-container *ngIf="!sideNavOnlyIcons">
          <div><small>{{ 'common.loggedIn' | translate }}</small></div>
          <div *ngIf="name$ | async as name"><b>{{name}}</b> ({{role}})</div>
        </ng-container>
      </mat-list-item>

      <hr>
      <br>
      <br>

      <ng-container *ngIf="{ categoryType: runningCategoryType$ | async, categories: categories$ | async, zoomFactor: zoomFactor$ | async } as running">

        <ng-container *ngFor="let favorite of favorites$ | async">
          <mat-list-item (click)="openFavorite(favorite)">
            <span>
              <mat-icon svgIcon="favorites"></mat-icon> {{ favorite.name }}
            </span>
          </mat-list-item>
        </ng-container>

        <mat-list-item routerLink="/profile" routerLinkActive="active">
          <span><mat-icon svgIcon="profile"></mat-icon> {{ 'navbar.profile' | translate }}</span>
        </mat-list-item>

        <mat-list-item *ngIf="hasHardware"
          routerLink="/testing" routerLinkActive="active">
          <span><mat-icon svgIcon="testing"></mat-icon> {{ 'navbar.testing' | translate }}</span>
        </mat-list-item>

        <mat-list-item *ngIf="hasChecklist"
          routerLink="/checklist" routerLinkActive="active">
          <span><mat-icon svgIcon="checklist"></mat-icon> {{ 'navbar.checklist' | translate }}</span>
        </mat-list-item>

        <ng-container *ngIf="role == ADMIN">
          <ng-container *ngFor="let link of linksAdmin">
            <mat-list-item routerLink="{{link.link}}" routerLinkActive="active">
              <span>
                <mat-icon [svgIcon]="link.icon"></mat-icon> {{ link.label | translate }}
              </span>
            </mat-list-item>
          </ng-container>
        </ng-container>
      </ng-container>

      <mat-list-item class="logout" (click)="onLogout()">
        <span><mat-icon>logout</mat-icon> {{ 'navbar.logout' | translate }}</span>
      </mat-list-item>
    </mat-nav-list>

  </mat-sidenav>

  <mat-sidenav-content>
    <router-outlet></router-outlet>
    <button mat-mini-fab (click)="toggleSidenav()" class="menu-button" *ngIf="(loggedIn$ | async) && runningCategoryType === CATEGORY_NONE">
      <mat-icon *ngIf="snav.opened">chevron_left</mat-icon>
      <mat-icon *ngIf="!snav.opened">chevron_right</mat-icon>
    </button>
  </mat-sidenav-content>

</mat-sidenav-container>

