import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { LOGGED_IN_REDIRECT_PATH } from 'src/app/lib/constants';
import { AuthService } from 'src/app/lib/service/auth.service';
import { LoginForm } from 'src/app/organisms/auth/login-form/login-form.component';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  loginForm: FormGroup<LoginForm> = this.formBuilder.group({
    email: new FormControl<string>('', [
      Validators.required,
      Validators.email,
    ]),
    password: new FormControl<string>('', [Validators.required]),
  });

  constructor(
    protected formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.authService.loggedIn$.next(false);
  }

  onSubmit() {
    this.authService
      .login(this.loginForm.value.email, this.loginForm.value.password)
      .subscribe(() => {
        this.router.navigate([LOGGED_IN_REDIRECT_PATH]);
      });
  }
}
