<h3>{{ 'reportSelect.title' | translate }}</h3>

<mat-form-field>
  <mat-label>{{ 'reportSelect.select' | translate }}</mat-label>
  <mat-select [(value)]="currentReport" (selectionChange)="onTypeSelection($event)">
    <mat-option *ngFor="let report of reports$ | async" [value]="report">
      {{ report.name }}
    </mat-option>
  </mat-select>
</mat-form-field>

<form *ngIf="currentReport !== null" [formGroup]="form" (ngSubmit)="onSubmit()">
  <h4>{{ 'reportSelect.header' | translate }}</h4>
  <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
</form>
