import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { PlayerPanelComponent } from './player-panel.component';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { VariableSearchPipe } from 'src/app/lib/pipe/variableSearch.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [PlayerPanelComponent, VariableSearchPipe],
  exports: [PlayerPanelComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    MatExpansionModule,
    MatIconModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule
  ],
})
export class PlayerPanelModule {}
