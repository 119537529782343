<div class="side-panel-card-1">
  <div class="side-panel-card-1-header">
    <h3>{{ 'player.savedVariables' | translate }}</h3>
  </div>
  <div class="side-panel-card-1-body">
    <div class="divTable var-table">
      <div class="divTableHeading">
        <div class="divTableRow">
          <div class="divTableHead">{{ 'player.variable.forced' | translate }}</div>
          <div class="divTableHead">{{ 'player.variable.name' | translate }}</div>
          <div class="divTableHead">{{ 'player.variable.address' | translate }}</div>
          <div class="divTableHead">{{ 'player.variable.description' | translate }}</div>
          <div class="divTableHead">{{ 'player.variable.value' | translate }}</div>
          <div class="divTableHead"></div>
        </div>
      </div>
      <div class="divTableBody">
        <div class="divTableRow" *ngFor="let variable of storageVariables$ | async">
          <div class="divTableCell"><mat-icon *ngIf="variable.isForced === true" fontIcon="check"></mat-icon></div>
          <div class="divTableCell">{{ variable.name }}</div>
          <div class="divTableCell">{{ variable.address }}</div>
          <div class="divTableCell">{{ variable.description }}</div>
          <div class="divTableCell" (click)="onEditVariable(variable)">{{ variable.value }}</div>
          <div class="divTableCell"><mat-icon fontIcon="remove" (click)="onRemoveVariable(variable)"></mat-icon></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="side-panel-card-2">
  <div class="side-panel-card-2-header">
    <h3>{{ 'player.hardwareVariables' | translate }}</h3>
    <form [formGroup]="variableSearchForm">
      <mat-form-field appearance="fill">
        <input matInput formControlName="search" placeholder="{{ 'player.search' | translate }}" />
      </mat-form-field>
    </form>
  </div>
  <div class="side-panel-card-2-body">
    <div class="divTable var-table">
      <div class="divTableHeading">
        <div class="divTableRow">
          <div class="divTableHead">{{ 'player.variable.name' | translate }}</div>
          <div class="divTableHead">{{ 'player.variable.address' | translate }}</div>
          <div class="divTableHead">{{ 'player.variable.description' | translate }}</div>
          <div class="divTableHead">{{ 'player.variable.value' | translate }}</div>
          <div class="divTableHead"></div>
        </div>
      </div>
      <div class="divTableBody">
        <div class="divTableRow" *ngFor="let variable of variables$ | async | variableSearch: variableSearchForm.value.search">
          <div class="divTableCell">{{ variable.name }}</div>
          <div class="divTableCell">{{ variable.address }}</div>
          <div class="divTableCell">{{ variable.description }}</div>
          <div class="divTableCell" (click)="onEditVariable(variable)">{{ variable.value }}</div>
          <div class="divTableCell"><mat-icon fontIcon="add" (click)="onAddVariable(variable)"></mat-icon></div>
        </div>
      </div>
    </div>
  </div>
</div>
