<div class="player-layout">
  <main class="main-block">
    <div class="title-bar">{{ 'report.title' | translate }}</div>
    <div class="report-main-container">
      <br>
      <ng-container *ngIf="headerItems$ | async">
        <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
      </ng-container>
    </div>
    <div class="report-main-container">  
      <div class="content">
        <h3>{{ 'report.annotation' | translate }}</h3>
        <app-user-messages-list></app-user-messages-list>
      </div>
    </div>
  </main>
  <aside class="side-panel">
    <div class="title-bar">{{ 'report.output' | translate }}</div>
    <div class="content">
      <button mat-flat-button color="primary" class="button"
        (click)="downloadPdfReport()"><mat-icon svgIcon="pdf"></mat-icon>{{ 'report.downloadPdf' | translate }}</button>
      <button mat-flat-button color="primary" class="button"
        (click)="downloadXmlReport()"><mat-icon svgIcon="xml"></mat-icon>{{ 'report.downloadXml' | translate }}</button>
      <ng-container *ngIf="webServices?.length">
        <div *ngFor="let webService of webServices">
          <button mat-flat-button color="primary" class="button" 
            (click)="storeReport(webService)">{{ webService.buttonTitle }}</button>
        </div>
      </ng-container>
      <ng-container *ngIf="category?.reportEmails">
        <button mat-flat-button color="primary" class="button" 
          (click)="emailReport()">{{ category?.reportEmailButton }}</button>
      </ng-container>
    </div>
  </aside>
</div>
