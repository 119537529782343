import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HardwareConnectorsPageRoutingModule } from './hardware-connectors-page-routing.module';
import { HardwareConnectorsPageComponent } from './hardware-connectors-page.component';


@NgModule({
  declarations: [
    HardwareConnectorsPageComponent
  ],
  imports: [
    CommonModule,
    HardwareConnectorsPageRoutingModule,
  ]
})
export class HardwareConnectorsPageModule { }
