import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-delete-confirm',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule, 
    MatDialogModule
  ],
  templateUrl: './delete-confirm.component.html',
  styleUrls: ['./delete-confirm.component.scss']
})
export class DeleteConfirmComponent {

  translation = {
    title: 'delete.title', 
    content: 'delete.content',
    cancel: 'delete.cancel',
    confirm: 'delete.confirm',
  }

  constructor(
    public dialogRef: MatDialogRef<DeleteConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) data: {
        title: string, 
        content: string, 
        confirm: string
      }
  ) {
    this.translation = {...this.translation, ...data}
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
