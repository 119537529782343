import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-file-input',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
  ],
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss']
})
export class FileInputComponent {
  @Input() allowedFileExtension: string;
  @Output() newFileDataEvent = new EventEmitter<string>();

  @ViewChild('fileDropRef') fileDropRef: ElementRef;

  files: File[] = [];

  /**
   * on file drop handler
   */
  onFileDropped($event: any) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(event: any) {
    this.prepareFilesList(event.target?.files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  async deleteFile(index: number) {
    this.files.splice(index, 1);
    if (this.fileDropRef) {
      this.fileDropRef.nativeElement.value = '';
    }
    this.newFileDataEvent.emit('');
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  async prepareFilesList(files: File[]) {
    for (const item of files) {
      if (this.files.length) {
        this.files.splice(0, 1); 
      }
      if (item.name.indexOf(this.allowedFileExtension) !== -1) {
        this.files.push(item);
        this.newFileDataEvent.emit(await this.getBase64(this.files[0]));
      }
    }
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes: number, decimals: number = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  private async getBase64(file: File) {
    return (await this.getDataURL(file) as string).split(',')[1];
  }

  private getDataURL(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });
  }

}
