import { Component } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { CompanyClient, WebServiceConfigurationDto, WebServiceParameterDto, WebServiceType } from 'src/app/lib/api/api.client';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-webservices-form',
  standalone: true,
  imports: [
    CommonModule, 
    TranslateModule,
    ReactiveFormsModule, 
    FormlyModule, 
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule
  ],
  templateUrl: './webservices-form.component.html',
  styleUrls: ['./webservices-form.component.scss'],
})
export class WebServicesFormComponent {
  form = new FormGroup({});

  model = {} as WebServiceConfigurationDto;

  fields: FormlyFieldConfig[] = [
    {
      key: 'id'
    },
    {
      key: 'name',
      type: 'input',
      props: {
        label: this.translateService.instant('webService.name'),
        required: true,
      },
    },
    {
      key: 'url',
      type: 'input',
      props: {
        label: this.translateService.instant('webService.url'),
        required: true,
      },
    },
    {
      key: 'title',
      type: 'input',
      props: {
        label: this.translateService.instant('webService.title'),
        required: true,
      },
    },
    {
      key: 'buttonTitle',
      type: 'input',
      props: {
        label: this.translateService.instant('webService.buttonTitle'),
        required: true,
      },
    },
    {
      key: 'type',
      /*
      type: 'select',
      props: {
        label: this.translateService.instant('webService.type'),
        required: true,
        options: [
          { label: 'GET', value: WebServiceType._0 },
          { label: 'POST', value: WebServiceType._1 },
        ],
      },
      */
    },
    {
      key: 'categoryId',
      /*
      type: 'select',
      props: {
        label: this.translateService.instant('testPlant.categoryName'),
        required: true,
        options: this.getCategories(),
      },
      */
    },
  ];

  parametersForm: FormGroup = this.fb.group({
    parameters: this.fb.array([])
  });

  get parameters() {
    return this.parametersForm.get('parameters') as FormArray;
  }

  GET_PROJECT = WebServiceType._0;

  constructor(
    private fb: FormBuilder,
    private location: Location, 
    private companyClient: CompanyClient,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.model = this.location.getState() as WebServiceConfigurationDto;

    this.initParameters();
  }

  initParameters() {
    if (this.model.parameters) {
      this.model.parameters.forEach((parameter: WebServiceParameterDto) => {
        this.addParameter(parameter);
      });
    }
  }

  onSubmit() {
    const parameters: WebServiceParameterDto[] = [];
    this.parameters.controls.forEach((control) => {
      parameters.push({
        id: control.get('id')?.value,
        name: control.get('name')?.value,
        label: control.get('label')?.value,
        value: control.get('value')?.value,
      });
    });

    const body = {
      ...this.form.value,
      ...{
        parameters
      }
    } as WebServiceConfigurationDto

    this.companyClient.postWebService(body).subscribe(() => {
      this.location.back();
    });
  }

  addParameter(parameter: WebServiceParameterDto | undefined = undefined) {
    const formGroup =  this.fb.group({
      id: parameter?.id,
      name: parameter?.name,
      label: parameter?.label,
      value: parameter?.value,
    });

    this.parameters.push(formGroup);
  }

  removeParameter(index: number) {
    const parameterId = this.parameters.at(index)?.get('id')?.value;
    if (parameterId) {
      const dto = { id: parameterId } as WebServiceParameterDto;
      this.companyClient.deleteWebServiceParameter(dto).subscribe();
    }
    this.parameters.removeAt(index);
  }
}
