<div class="card">
  <h3 *ngIf="!model.id">{{ 'form.add' | translate }}</h3>
  <h3 *ngIf="model.id">{{ 'form.edit' | translate }}</h3>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>

    <ng-container *ngIf="model.type === GET_PROJECT">
      <h4>{{ 'webService.parameter.header' | translate }}</h4>

      <div [formGroup]="parametersForm">
        <table formArrayName="parameters">
          <ng-container *ngFor="let paramter of parameters.controls; let i = index">
          <tr [formGroupName]="i">
            <td class="label">
              <mat-form-field>
                <mat-label>{{ 'webService.parameter.name' | translate }}</mat-label>
                <input matInput formControlName="name">
              </mat-form-field>
            </td>
            <td class="separator">:</td>
            <td class="label">
              <mat-form-field>
                <mat-label>{{ 'webService.parameter.label' | translate }}</mat-label>
                <input matInput formControlName="label">
              </mat-form-field>
            </td>
            <td>
              <button mat-icon-button (click)="removeParameter(i)">
                <mat-icon>close</mat-icon>
              </button>
            </td>
          </tr>
          </ng-container>
        </table>
      </div>
      <button type="button" mat-stroked-button (click)="addParameter()">
        {{ 'webService.parameter.add' | translate }}
      </button>
    </ng-container>

    <br>
    <br>
    <br>

    <button type="submit" mat-flat-button color="primary">{{ 'form.save' | translate }}</button>
  </form>
</div>
