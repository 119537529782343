import { autoserializeAs } from 'cerializr';
import { Column } from 'src/app/lib/components/table/column';

export class WebService {
  @autoserializeAs(String)
  id: string | undefined;
  @autoserializeAs(String)
  @Column({
    canSort: true,
  })
  name: string | undefined;
}

