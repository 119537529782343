import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HardwareInterfaceStateDto, HardwareState } from 'src/app/lib/api/api.client';
import { OverlayModule } from '@angular/cdk/overlay';

const NotAvailable = HardwareState._0;
const NotConnected = HardwareState._1;
const NotConnectedButNotNeeded = HardwareState._2;
const Connected = HardwareState._3;

@Component({
  selector: 'app-hardware-status',
  standalone: true,
  imports: [CommonModule, OverlayModule],
  templateUrl: './hardware-status.component.html',
  styleUrls: ['./hardware-status.component.scss'],
})
export class HardwareStatusComponent {
  @Input() hardwareStatus!: HardwareInterfaceStateDto[];
  isOpen = false;

  aggregateStatus(hardwareStatus: HardwareInterfaceStateDto[]): number {
    if (hardwareStatus.find((status) => status.hardwareState === NotAvailable)) {
      return 0;
    }

    if (hardwareStatus.find((status) => status.hardwareState === NotConnected)) {
      return 1;
    }

    if (hardwareStatus.find((status) => status.hardwareState === NotConnectedButNotNeeded)) {
      return 2;
    }

    return 3;
  }

  getStatusClass(statusCode: number | undefined): string {
    switch (statusCode) {
      case NotAvailable:
        return 'not-available';
      case NotConnected:
        return 'not-connected';
      case NotConnectedButNotNeeded:
        return 'not-connected-but-not-needed';
      case Connected:
        return 'connected';
      default:
        return '';
    }
  }
}
