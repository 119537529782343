<div class="card">
  <h3 *ngIf="!model.id">{{ 'form.add' | translate }}</h3>
  <h3 *ngIf="model.id">{{ 'form.edit' | translate }}</h3>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
    <button type="submit" mat-flat-button color="primary">{{ 'form.save' | translate }}</button>
  </form>
</div>
<div class="card" *ngIf="model.id && hardwareConnectors != undefined">
  <app-hardware-connectors-list [testPlantId]="model.id" [data]="hardwareConnectors"></app-hardware-connectors-list>
</div>