import { Component } from '@angular/core';

@Component({
  selector: 'app-test-plants-page',
  templateUrl: './test-plants-page.component.html',
  styleUrls: ['./test-plants-page.component.scss']
})
export class TestPlantsPageComponent {

}
