<div class="player-layout" [ngClass]="{ 'player-layout-grid': panelOpen }">
  <main class="main-block">
    <div>
      <app-player-main
        [category]="category"
        [messages$]="messages$"
        [images$]="images$"
        [meta$]="meta$"
        [appStatus$]="appStatus$"
        [hardwareStatus$]="hardwareStatus$"
      ></app-player-main>
    </div>
    <app-player-actions
      [ngClass]="{'hidden': buttonsLocked}"
      [buttons$]="buttons$"
      (onAction)="onPlayerAction($event)"
    ></app-player-actions>
    <button mat-mini-fab (click)="panelOpen = !panelOpen;" class="panel-button">
      <mat-icon *ngIf="panelOpen">chevron_right</mat-icon>
      <mat-icon *ngIf="!panelOpen">chevron_left</mat-icon>
    </button>
  </main>
  <aside *ngIf="panelOpen" class="side-panel">
    <app-player-panel
      [variables$]="variables$"
      [storageVariables$]="storageVariables$"
      (addVariable)="addFavouriteVariable($event)"
      (removeVariable)="removeFavouriteVariable($event)"
      (editVariable)="editVariable($event)"
    ></app-player-panel>
  </aside>
</div>
