import { Pipe, PipeTransform } from '@angular/core';
import { VariableDto } from '../api/api.client';
@Pipe({
  name: 'variableSearch',
})
export class VariableSearchPipe implements PipeTransform {
  transform(
    value: VariableDto[] | null,
    search: string | undefined | null
  ): VariableDto[] | null {
    if (value && search) {

      return [
        ...value.filter((item) => {
          return item.name?.includes(search);
        }),
      ];
    } else {
      return value;
    }
  }
}