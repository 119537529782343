import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WebServicesPageComponent } from './webservices-page.component';
import { WebServicesPageRoutingModule } from './webservices-page-routing.module';

@NgModule({
  declarations: [
    WebServicesPageComponent
  ],
  imports: [
    CommonModule,
    WebServicesPageRoutingModule
  ]
})
export class WebServicesPageModule { }
