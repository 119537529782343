import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { FileDto } from 'src/app/lib/api/api.client';
import { IImage } from 'ng-simple-slideshow';

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss'],
})
export class ImageGalleryComponent implements OnInit, OnDestroy {
  @Input() images$!: Observable<Array<FileDto>>;
  private readonly unsubscribe$ = new Subject();
  slideshow: Array<IImage> = [];

  ngOnInit(): void {
    this.images$.pipe(
      takeUntil(this.unsubscribe$),
      map((images) => images.map((image) => ({
        url: image.data,
        title: image.name,
        caption: image.name
      }) as IImage))
    ).subscribe((result) => this.slideshow = result);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  constructor() {}

  close(): void {}
}
