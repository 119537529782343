import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatStepperModule } from '@angular/material/stepper';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';

import { TestingFlowRoutingModule } from './testing-flow-routing.module';
import { TestingFlowComponent } from './testing-flow.component';
import { PlayerPageComponent } from './player-page/player-page.component';
import { PlayerMainModule } from 'src/app/organisms/player-main/player-main.module';
import { PlayerPanelModule } from 'src/app/organisms/player-variables/player-panel.module';
import { PlayerActionsModule } from 'src/app/molecules/player-actions/player-actions.module';
import { ReportPageComponent } from './report-page/report-page.component';
import { ProjectSelectComponent } from 'src/app/organisms/configuration/project-select/project-select.component';
import { ReportSelectComponent } from 'src/app/organisms/configuration/report-select/report-select.component';
import { TestPlantSelectComponent } from 'src/app/organisms/configuration/test-plant-select/test-plant-select.component';
import { MatButtonModule } from '@angular/material/button';
import { FormlyModule } from '@ngx-formly/core';
import { UserMessagesListComponent } from 'src/app/organisms/crud/user-messages-list/user-messages-list.component';
import { CategorySelectComponent } from 'src/app/organisms/configuration/category-select/category-select.component';
import { WebServiceSelectComponent } from 'src/app/organisms/configuration/webservice-select/webservice-select.component';

@NgModule({
  declarations: [
    TestingFlowComponent,
    PlayerPageComponent,
    ReportPageComponent
  ],
  imports: [
    CommonModule,
    TestingFlowRoutingModule,
    PlayerMainModule,
    PlayerPanelModule,
    PlayerActionsModule,
    ProjectSelectComponent,
    ReportSelectComponent,
    CategorySelectComponent,
    TestPlantSelectComponent,
    WebServiceSelectComponent,
    UserMessagesListComponent,
    MatDialogModule,
    MatStepperModule,
    MatButtonModule,
    TranslateModule.forChild(),
    MatIconModule,
    FormlyModule
  ]
})
export class TestingFlowModule { }
