import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageDto, MessageFormat, PlayerClient, Severity, UploadExcelDto, UploadWordDto } from 'src/app/lib/api/api.client';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { ImportTableComponent, ImportTableData } from '../import-table/import-table.component';
import { MatDialog } from '@angular/material/dialog';
import { ImportImageComponent } from '../import-image/import-image.component';
import { ImportWordComponent, ImportWordData } from '../import-word/import-word.component';
import { SEVERITY_ERROR, SEVERITY_INFO, SEVERITY_PENDING, SEVERITY_SUCCESS, SEVERITY_SUMMARY, SEVERITY_USER_DEFINED } from 'src/app/lib/constants';

export type FileEventTarget = EventTarget & { files: FileList };

@Component({
  selector: 'app-message',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent {
  @Input() message!: MessageDto;
  @Input() isLastMessage: boolean;

  INFO = SEVERITY_INFO;
  SUCCESS = SEVERITY_SUCCESS;
  ERROR = SEVERITY_ERROR;
  SUMMARY = SEVERITY_SUMMARY;
  PENDING = SEVERITY_PENDING;
  USER_DEFINED = SEVERITY_USER_DEFINED;

  imageBase64Urls: string[];
  tableData: ImportTableData;
  wordData: ImportWordData;

  constructor(
    private dialog: MatDialog,
    private playerClient: PlayerClient
  ) {}

  getClass(severity: Severity | undefined, format: MessageFormat | undefined) {
    return `${this.getClassSeverity(severity)} ${this.getClassMessageFormat(format)}`
  }

  imageImport() {
    this.dialog
      .open(ImportImageComponent, { data: {imageBase64Urls: this.imageBase64Urls} })
      .afterClosed().subscribe((dialogResult) => {
        if (dialogResult) {
          this.imageBase64Urls = dialogResult;
          this.playerClient.uploadImages(this.imageBase64Urls).subscribe();
        }
      });
  }

  tableImport() {
    this.dialog
      .open(ImportTableComponent, { width: '400px', data: {tableData: this.tableData} })
      .afterClosed().subscribe(async (dialogResult) => {
        if (dialogResult) {
          this.tableData = dialogResult;

          const dto = <UploadExcelDto> {
            base64: await this.getBase64(this.tableData.fileEvent.files[0]),
            filename: this.tableData.fileEvent.files[0].name,
            sheetName: this.tableData.sheetName, 
            cellRange: this.tableData.cellRange,
            tableOutputOption: this.tableData.tableOutputOptions,
            argumentColumn: this.tableData.argumentColumn,
            valueColumn: this.tableData.valueColumn,
          }

          this.playerClient.uploadExcelFile(dto).subscribe();
        }
      });
  }

  wordImport() {
    this.dialog
      .open(ImportWordComponent, { width: '400px', data: {wordData: this.wordData} })
      .afterClosed().subscribe(async (dialogResult) => {
        if (dialogResult) {
          this.wordData = dialogResult;
          this.playerClient.uploadWordFile({
            base64: await this.getBase64(this.wordData.fileEvent.files[0]),
            filename: this.wordData.fileEvent.files[0].name
          } as UploadWordDto).subscribe();
        }
      });
  }

  private getClassSeverity(id: Severity | undefined) {
    switch (id) {
      case SEVERITY_INFO:
        return 'severity-info';
      case SEVERITY_SUCCESS:
        return 'severity-success';
      case SEVERITY_ERROR:
        return 'severity-error';
      case SEVERITY_SUMMARY:
        return 'severity-summary';
      default:
        return '';
    }
  }

  private getClassMessageFormat(id: MessageFormat | undefined) {
    switch (id) {
      case 0:
        return 'message-standard';
      case 1:
        return 'message-subheading';
      case 2:
        return 'message-heading';
      case 3:
        return 'message-chapterheading';
      default:
        return '';
    }
  }

  private async getBase64(file: File) {
    return (await this.getDataURL(file) as string).split(',')[1];
  }

  private getDataURL(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });
  }
}
