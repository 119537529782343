import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { ProjectsPageRoutingModule } from './projects-page-routing.module';
import { ProjectsPageComponent } from './projects-page.component';

@NgModule({
  declarations: [
    ProjectsPageComponent,
  ],
  imports: [
    CommonModule,
    ProjectsPageRoutingModule,
  ],
})
export class ProjectsPageModule {}
