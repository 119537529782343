import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserDto, UserMessageDto } from 'src/app/lib/api/api.client';
import { BehaviorSubject, Observable, Subject, map, switchMap } from 'rxjs';
import { UserMessage } from './user-message.model';
import { TableComponent } from 'src/app/lib/components/table/table.component';
import { MatButtonModule } from '@angular/material/button';
import { Router, RouterModule } from '@angular/router';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { DeleteConfirmComponent } from 'src/app/atom/delete-confirm/delete-confirm.component';
import { TranslateModule } from '@ngx-translate/core';
import { DeserializeArray, JsonArray } from 'cerializr';
import { ReportService } from 'src/app/lib/service/report.service';

@Component({
  selector: 'app-user-messages-list',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    TableComponent, 
    MatButtonModule, 
    RouterModule, 
    MatDialogModule
  ],
  templateUrl: './user-messages-list.component.html',
  styleUrls: ['./user-messages-list.component.scss']
})
export class UserMessagesListComponent {
  private notification$: Subject<null> = new BehaviorSubject<null>(null);
  userMessages$: Observable<UserMessage[]> = this.reportService.userMessages$.asObservable()
    .pipe(map((res) => DeserializeArray(res as JsonArray, UserMessage)));;
  data$ = this.notification$.pipe(switchMap(() => this.userMessages$));
  
  constructor(
    private router: Router,
    private reportService: ReportService,
    public dialog: MatDialog,
  ) {}
  
  onDelete(entry: UserMessageDto) {
    const dialogRef = this.dialog.open(DeleteConfirmComponent);
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        var userMessages = this.reportService.userMessages$.getValue();
        var filteredMessages = userMessages.filter((userMessage) => userMessage.text !== entry.text && userMessage.severity !== entry.severity);
        this.reportService.userMessages$.next(filteredMessages);
      }
    });
  }

  onEdit(entry: UserDto) {
    this.router.navigate(['/testing/edit'], { state: entry });
  }
}
