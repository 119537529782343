import { Component } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { CategoryDto, CheckInProjectDto, CompanyClient, ProblemDetails, ProjectDto, Result, WebServiceConfigurationDto } from 'src/app/lib/api/api.client';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs';
import { FileInputComponent } from 'src/app/molecules/file-input/file-input.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-projects-form',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormlyModule,
    MatButtonModule,
    MatFormFieldModule,
    FileInputComponent
  ],
  templateUrl: './projects-form.component.html',
  styleUrls: ['./projects-form.component.scss'],
})
export class ProjectsFormComponent {
  form = new FormGroup({});
  model = {} as ProjectDto;
  fields: FormlyFieldConfig[] = [
    {
      key: 'id'
    },
    {
      key: 'name',
      type: 'input',
      hide: true,
    },
    {
      key: 'version',
      type: 'input',
      hide: true,
    },
    {
      key: 'categoryId',
      type: 'select',
      props: {
        label: this.translateService.instant('testPlant.categoryName'),
        required: true,
        options: this.getCategories(),
      },
    },
  ];

  missingLibrariesError: string | undefined = '';

  constructor(
    private router: Router, 
    private location: Location, 
    private companyClient: CompanyClient,
    private translateService: TranslateService,
    private snack: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.model = this.location.getState() as ProjectDto;
    this.form.addControl('fileData', new FormControl(this.model.fileData, Validators.required));
  }

  checkIn(webService: WebServiceConfigurationDto) {
    const dto = {
      ...webService,
      ...{
        projectId: this.model.id
      }
    } as CheckInProjectDto;
    
    this.companyClient.checkInProjectByWebService(dto).subscribe((result: Result) => {
      if (result.success) {
        this.snack.open(this.translateService.instant('form.submitSuccess'));
      } else if (!result.success && result.message) {
        this.snack.open(result.message);
      } else {
        this.snack.open(this.translateService.instant('error.500'));
      }
    });
  }

  async onSubmit() {
    if (!this.form.valid) {
      return;
    }

    const dto = <ProjectDto>{
      ...this.form.value,
    };

    this.companyClient.postProject(dto).subscribe(() => {
      this.missingLibrariesError = undefined;
      this.router.navigate(["/projects"]);
    }, (err: ProblemDetails) => {;
      this.missingLibrariesError = err.detail;
    });
  }

  updateFileData(fileData: string) {
    this.form.patchValue({fileData: fileData})
  }

  private getCategories(): any {
    const data = this.companyClient.getCategories().pipe(
      map((items: CategoryDto[])=> {
        return items.map((item: CategoryDto) => {
          return { value: item.id, label: item.name };
        });
      })
    );
    return data;
  }
}
