import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from 'src/app/lib/components/table/table.component';
import { BehaviorSubject, Observable, Subject, map, of, switchMap } from 'rxjs';
import { WebService } from './webservice.model';
import { DeserializeArray, JsonArray } from 'cerializr';
import { CompanyClient, WebServiceConfigurationDto, WebServiceType } from 'src/app/lib/api/api.client';
import { MatButtonModule } from '@angular/material/button';
import { Router, RouterModule } from '@angular/router';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { DeleteConfirmComponent } from 'src/app/atom/delete-confirm/delete-confirm.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-webservices-list',
  standalone: true,
  imports: [
    CommonModule, 
    TranslateModule,
    TableComponent, 
    MatButtonModule, 
    RouterModule, 
    MatDialogModule],
  templateUrl: './webservices-list.component.html',
  styleUrls: ['./webservices-list.component.scss'],
})
export class WebServicesListComponent implements OnInit {
  @Input() categoryId: string | undefined;
  @Input() webServiceType: WebServiceType;
  @Input() webServices: WebServiceConfigurationDto[] | undefined;

  @Output() onChange = new EventEmitter<any>();

  private originalWebServices: WebServiceConfigurationDto[] | undefined;

  private notification$: Subject<null> = new BehaviorSubject<null>(null);

  webServices$: Observable<WebService[]>;

  data$ = this.notification$.pipe(switchMap(() => this.webServices$));

  GET_PROJECT = WebServiceType._0;
  POST_PROJECT = WebServiceType._1;
  POST_REPORT = WebServiceType._2;

  constructor(
    private router: Router, 
    private snack: MatSnackBar,
    private translate: TranslateService,
    private companyClient: CompanyClient, 
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.webServices$ = of(this.webServices)
      .pipe(map((res) => {
        this.originalWebServices = res;
        return DeserializeArray(res as JsonArray, WebService)
      }));
  }

  onDelete(entry: WebServiceConfigurationDto) {
    const dialogRef = this.dialog.open(DeleteConfirmComponent);
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.companyClient.deleteWebServiceConfiguration(entry).subscribe(() => {
          this.snack.open(this.translate.instant('common.deleted'));
          this.notification$.next(null);
          this.onChange.emit(true);
        });
      }
    });
  }

  onNew() {
    this.router.navigate(['/webservices/add'], { state: { categoryId: this.categoryId, type: this.webServiceType } });
  }
  
  onEdit(entry: WebService) {
    const item = this.originalWebServices?.find((item: WebServiceConfigurationDto) => item.id === entry.id);
    this.router.navigate(['/webservices/edit'], { state: item });
  }
}
