import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HardwareConnectorsPageComponent } from './hardware-connectors-page.component';
import { HardwareConnectorsListComponent } from 'src/app/organisms/crud/hardware-connectors-list/hardware-connectors-list.component';
import { HardwareConnectorsFormComponent } from 'src/app/organisms/crud/hardware-connectors-form/hardware-connectors-form.component';

const routes: Routes = [{
  path: '',
  component: HardwareConnectorsPageComponent,
  children: [
    {
      path: '',
      component: HardwareConnectorsListComponent
    },
    {
      path: 'add',
      component: HardwareConnectorsFormComponent
    },
    {
      path: 'edit',
      component: HardwareConnectorsFormComponent
    }
  ]
},];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HardwareConnectorsPageRoutingModule { }
