import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { VariableDto } from 'src/app/lib/api/api.client';

@Component({
  selector: 'app-player-panel',
  templateUrl: './player-panel.component.html',
  styleUrls: ['./player-panel.component.scss'],
})
export class PlayerPanelComponent {
  @Input() variables$: Observable<VariableDto[]> | undefined;
  @Input() storageVariables$!: Observable<VariableDto[]>;
  @Output() addVariable = new EventEmitter();
  @Output() removeVariable = new EventEmitter();
  @Output() editVariable = new EventEmitter();

  variableSearchForm = this.formBuilder.group({
    search: '',
  });

  constructor(private formBuilder: FormBuilder) {}

  onAddVariable(variable: VariableDto): void {
    this.addVariable.emit(variable);
  }

  onRemoveVariable(variable: VariableDto): void {
    this.removeVariable.emit(variable);
  }

  onEditVariable(variable: VariableDto): void {
    this.editVariable.emit(variable);
  }
}
