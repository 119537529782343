import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { ButtonState, ButtonStateDto, ButtonType } from 'src/app/lib/api/api.client';
import { CONFIRM_BUTTON, DISABLED_STATE, ENABLED_STATE, INVISIBLE_STATE, REPEAT_BUTTON, SKIP_BUTTON, START_BUTTON, STOP_BUTTON } from 'src/app/lib/constants';

@Component({
  selector: 'app-player-actions',
  templateUrl: './player-actions.component.html',
  styleUrls: ['./player-actions.component.scss']
})
export class PlayerActionsComponent {
  @Input() buttons$!: Observable<ButtonStateDto[]>;
  @Output() onAction: EventEmitter<any> = new EventEmitter();

  ENABLED = ENABLED_STATE;
  DISABLED = DISABLED_STATE;
  INVISIBLE = INVISIBLE_STATE;

  START_BUTTON = START_BUTTON;
  STOP_BUTTON = STOP_BUTTON;
  REPEAT_BUTTON = REPEAT_BUTTON;
  SKIP_BUTTON = SKIP_BUTTON;
  CONFIRM_BUTTON = CONFIRM_BUTTON;

  getClass(buttonState: ButtonState | undefined) {
    switch (buttonState) {
      case 0:
        return 'active';
      case 1:
        return 'disabled';
      case 2:
        return 'invisible';
      default:
        return ''
    }
  }

  performAction(buttonType: ButtonType | undefined) {
    this.onAction.emit(buttonType);
  }
}
