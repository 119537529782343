import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoryDto, ChooseProjectResult, ProjectClient, WebServiceConfigurationDto, WebServiceParameterDto } from 'src/app/lib/api/api.client';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Observable, map, of } from 'rxjs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CorrectionValuesComponent } from '../correction-values/correction-values.component';
import { ProjectSelectComponent } from '../project-select/project-select.component';
import { NOT_EXECUTABLE_ON_TEST_PLANT, NOT_FOUND } from 'src/app/lib/constants';

@Component({
  selector: 'app-webservice-select',
  standalone: true,
  imports: [
    CommonModule, 
    TranslateModule,
    MatButtonModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    CorrectionValuesComponent,
    ProjectSelectComponent,
  ],
  providers: [ProjectClient],
  templateUrl: './webservice-select.component.html',
  styleUrls: ['./webservice-select.component.scss']
})
export class WebServiceSelectComponent implements OnInit {
  @Output() onProjectResult = new EventEmitter<ChooseProjectResult>();

  @Input() category: CategoryDto | undefined;
  @Input() webServices: WebServiceConfigurationDto[] | undefined;

  model: WebServiceConfigurationDto = {};

  currentWebService: WebServiceConfigurationDto | undefined | null;

  chooseProjectResult: ChooseProjectResult | undefined;

  parametersForm: FormGroup;
  get parameters() {
    return this.parametersForm.get('parameters') as FormArray;
  }

  NOT_FOUND = NOT_FOUND;
  NOT_EXECUTABLE = NOT_EXECUTABLE_ON_TEST_PLANT;

  constructor (
    private fb: FormBuilder,
    private projectClient: ProjectClient
  ) {}

  ngOnInit() {
    if (this.webServices && this.webServices.length === 1) {
      this.currentWebService = this.webServices[0];
    } else {
      this.webServices?.forEach((webService: WebServiceConfigurationDto) => {
        if (this.category?.chooseProjectWebService == webService.id) {
          this.currentWebService = webService;
        }
      });
    }
    this.initParameters();
  }

  initParameters() {
    if (this.currentWebService?.parameters) {
      this.parametersForm = this.fb.group({
        parameters: this.fb.array([])
      });
    
      this.currentWebService.parameters.forEach((parameter: WebServiceParameterDto) => {
        this.addParameter(parameter);
      });
    }
  }

  addParameter(parameter: WebServiceParameterDto | undefined = undefined) {
    const formGroup =  this.fb.group({
      id: parameter?.id,
      name: parameter?.name,
      label: parameter?.label,
      value: parameter?.value,
    });

    this.parameters.push(formGroup);
  }

  onSelection(event: MatSelectChange) {
    this.chooseProjectResult = undefined;
    this.currentWebService = event.value;
    this.initParameters();
  }

  getProject() {
    if (this.currentWebService) {
      const parameters: WebServiceParameterDto[] = [];
      this.parameters.controls.forEach((control) => {
        parameters.push({
          id: control.get('id')?.value,
          name: control.get('name')?.value,
          label: control.get('label')?.value,
          value: control.get('value')?.value,
        });
      });

      const dto = {
        ...this.currentWebService,
        ...{
          parameters
        }
      };
      this.projectClient.chooseProjectByWebService(dto).subscribe((result: ChooseProjectResult) => {
        this.onProjectResult.emit(result);
        this.chooseProjectResult = result;
      });
    }
  }

  onSubmit(): Observable<void> {
    if (this.chooseProjectResult?.correctionValues?.length) {
      return this.projectClient.setCorrectionValues(this.chooseProjectResult?.correctionValues)
      .pipe(map(() => {
        return;
      }));
    } else {
      return of(void 0);
    }
  }
}
