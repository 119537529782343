<div class="player-actions-container">
  <ng-container *ngFor="let button of buttons$ | async">
    <div
      class="action"
      [ngSwitch]="button.buttonType"
      [class]="getClass(button.buttonState)"
      *ngIf="button.buttonState !== INVISIBLE"
      (click)="button.buttonState === ENABLED && performAction(button.buttonType)"
    >
      <!--
        Displayed on right side of stepper
        <mat-icon *ngSwitchCase="0" fontIcon="play_circle"></mat-icon>
      -->
      <mat-icon *ngSwitchCase="REPEAT_BUTTON" fontIcon="replay_circle_filled"></mat-icon>
      <mat-icon *ngSwitchCase="SKIP_BUTTON" fontIcon="skip_next"></mat-icon>
      <mat-icon *ngSwitchCase="CONFIRM_BUTTON" fontIcon="playlist_add_check_circle"></mat-icon>
    </div>
  </ng-container>
</div>
