import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UsersPageComponent } from './users-page.component';
import { UsersListComponent } from 'src/app/organisms/crud/users-list/users-list.component';
import { UsersFormComponent } from 'src/app/organisms/crud/users-form/users-form.component';

const routes: Routes = [
  {
    path: '',
    component: UsersPageComponent,
    children: [
      {
        path: '',
        component: UsersListComponent
      },
      {
        path: 'add',
        component: UsersFormComponent
      },
      {
        path: 'edit',
        component: UsersFormComponent
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersPageRoutingModule { }
