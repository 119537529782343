import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { CategoryDto, FavoriteDto, ProgressStepDto } from '../api/api.client';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  favoritesSubject = new BehaviorSubject<FavoriteDto[]>([]);
  categoriesSubject = new BehaviorSubject<CategoryDto[]>([]);
  currentProgressStepSubject = new BehaviorSubject<ProgressStepDto[]>([]);

  favorites$ = this.favoritesSubject.asObservable();
  categories$ = this.categoriesSubject.asObservable();
  currentProgressStep$ = this.currentProgressStepSubject.asObservable();

  constructor() {}

  updateFavorites(data: FavoriteDto[] | undefined) {
    if (data) {
      this.favoritesSubject.next(data);
    }
  }

  updateCategories(data: CategoryDto[] | undefined) {
    if (data) {
      this.categoriesSubject.next(data);
    }
  }

  updateCurrentProgressStep(data: ProgressStepDto[] | undefined) {
    if (data) {
      this.currentProgressStepSubject.next(data);
    }
  }
}