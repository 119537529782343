import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-overwrite-report',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule, 
    MatDialogModule
  ],
  templateUrl: './overwrite-report.component.html',
  styleUrls: ['./overwrite-report.component.scss']
})
export class OverwriteReportComponent {
  constructor(public dialogRef: MatDialogRef<OverwriteReportComponent>) 
  {}

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
