import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LibrariesPageComponent } from './libraries-page.component';
import { LibrariesListComponent } from 'src/app/organisms/crud/libraries-list/libraries-list.component';
import { LibrariesFormComponent } from 'src/app/organisms/crud/libraries-form/libraries-form.component';

const routes: Routes = [{
  path: '',
  component: LibrariesPageComponent,
  children: [
    {
      path: '',
      component: LibrariesListComponent
    },
    {
      path: 'add',
      component: LibrariesFormComponent
    },
    {
      path: 'edit',
      component: LibrariesFormComponent
    }
  ]
},];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LibrariesPageRoutingModule { }
