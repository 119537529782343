<div *ngIf="message.severity !== USER_DEFINED" class="message-container">
  <div class="icon-container" *ngIf="message.severity === ERROR || message.severity === SUCCESS || message.severity === PENDING">
    <mat-icon
      [class]="getClass(message.severity, message.messageFormat)"
      *ngIf="message.severity === ERROR">error</mat-icon>
    <mat-icon
      [class]="getClass(message.severity, message.messageFormat)"
      *ngIf="message.severity === SUCCESS">check_circle</mat-icon>
    <mat-spinner 
      *ngIf="message.severity === PENDING" diameter="15">
    </mat-spinner>
  </div>
  <p [class]="getClass(message.severity, message.messageFormat)" [innerHTML]="message.text"></p>
  <ng-container *ngIf="isLastMessage && (message.enableImageImport || message.enableTableImport || message.enableWordImport)">
    <div class="button-row">
      <button *ngIf="message.enableImageImport" mat-icon-button (click)="imageImport()"><mat-icon svgIcon="images"></mat-icon></button>
      <button *ngIf="message.enableTableImport" mat-icon-button (click)="tableImport()"><mat-icon svgIcon="xlsx"></mat-icon></button>
      <button *ngIf="message.enableWordImport" mat-icon-button (click)="wordImport()"><mat-icon svgIcon="docx"></mat-icon></button>
    </div>
  </ng-container>
  <ng-container *ngIf="!isLastMessage && message.containsUserAttachment">
    <div class="button-row">
      <mat-icon class="non-svg-icon">attachment</mat-icon>
    </div>
  </ng-container>
</div>

