import { Component } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { UserClient, UserDto } from 'src/app/lib/api/api.client';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-users-form',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule, 
    FormlyModule, 
    MatButtonModule
  ],
  templateUrl: './users-form.component.html',
  styleUrls: ['./users-form.component.scss']
})
export class UsersFormComponent {
  form = new FormGroup({});
  model = {} as UserDto;
  fields: FormlyFieldConfig[] = [
    {
      key: 'id'
    },
    {
      key: 'firstName',
      type: 'input',
      props: {
        label: this.translateService.instant('user.firstName'),
        required: true,
      },
    },
    {
      key: 'lastName',
      type: 'input',
      props: {
        label: this.translateService.instant('user.lastName'),
        required: true,
      },
    },
    {
      key: 'email',
      type: 'input',
      props: {
        label: this.translateService.instant('user.email'),
        required: true,
      },
    },
    {
      key: 'roleName',
      type: 'select',
      props: {
        label: this.translateService.instant('user.roleName'),
        required: true,
        options: [
          { label: 'Administrator', value: 'Administrator' },
          { label: 'Tester', value: 'Tester' },
        ],
      },
    },
  ];

  constructor(
    private router: Router, 
    private location: Location, 
    private userClient: UserClient,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.model = this.location.getState() as UserDto;
  }

  onSubmit() {
    this.userClient.postUser(this.form.value).subscribe(() => {
      this.router.navigate(["/users"]);
    })
  }
}
