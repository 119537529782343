import { Component, EventEmitter, Output, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MessageDto, MessageResult } from 'src/app/lib/api/api.client';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { DeleteConfirmComponent } from 'src/app/atom/delete-confirm/delete-confirm.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-define-message',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatRadioModule,
    MatIconModule,
  ],
  templateUrl: './define-message.component.html',
  styleUrls: ['./define-message.component.scss'],
})
export class DefineMessageComponent {
  @Output() submitClicked = new EventEmitter<any>();

  description!: string;

  form = this.formBuilder.group({
    messageResult: new FormControl(null)
  });

  SUCCESS = MessageResult._1;
  FAILURE = MessageResult._2;

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<DefineMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MessageDto
  ) {}

  onStop(): void {
    const dialogRef = this.dialog.open(DeleteConfirmComponent, {
      data: { 
        title: 'stopDialog.title', 
        content: 'stopDialog.content',
        confirm: 'stopDialog.confirm' 
      },
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.submitClicked.emit(null);
        this.dialogRef.close();
      }
    });
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.submitClicked.emit(this.form.value);
      this.dialogRef.close();
    }
  }
}

