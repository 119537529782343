<h2 mat-dialog-title>{{ data.title }}</h2>

<mat-dialog-content>
  <p>{{ data.message }}</p>

  <form [formGroup]="form" (ngSubmit)="onSubmit()" id="form">
    <mat-form-field>
      <input matInput placeholder="" formControlName="text" />
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button form="form" type="submit" mat-flat-button color="primary" [disabled]="!form.valid">
    <mat-icon>check</mat-icon> {{ 'defineMessageDialog.confirm' | translate }}
  </button>
</mat-dialog-actions>
