<ng-container *ngIf="correctionValues?.length">
  <div class="divTable var-table">
    <div class="divTableHeading">
      <div class="divTableRow">
        <div class="divTableHead">{{ 'player.variable.name' | translate }}</div>
        <div class="divTableHead">{{ 'player.variable.description' | translate }}</div>
        <div class="divTableHead">{{ 'player.variable.value' | translate }}</div>
      </div>
    </div>
    <div class="divTableBody">
      <div class="divTableRow" *ngFor="let variable of correctionValues">
        <div class="divTableCell padding-right">{{ variable.name }}</div>
        <div class="divTableCell padding-right">{{ variable.description }}</div>
        <div class="divTableCell pointer" (click)="toggleCorrectionValue(variable)">
          <ng-container *ngIf="isTextInput(variable)">
            <input matInput [(ngModel)]="variable.value" class="settings-input" />
          </ng-container>
          <ng-container *ngIf="!isTextInput(variable)">
            <mat-checkbox color="primary" [checked]="getCorrectionValue(variable)"></mat-checkbox>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>
