<h2 mat-dialog-title>{{ data.name }}</h2>

<mat-dialog-content>
  <form [formGroup]="form" (ngSubmit)="onSubmit()" id="form">
    <mat-form-field>
      <input matInput placeholder="Value" formControlName="value" />
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-flat-button (click)="close()">Close</button>
  <button form="form" type="submit" mat-flat-button color="primary">Submit</button>
</mat-dialog-actions>
