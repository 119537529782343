import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import {
  CompanyClient,
  CategoryClient,
  CategoryDto,
  CategoryType,
} from 'src/app/lib/api/api.client';
import { Observable, map } from 'rxjs';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { MatButtonModule } from '@angular/material/button';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { TranslateModule } from '@ngx-translate/core';
import { CHECKLIST_TYPE, HARDWARE_TYPE } from 'src/app/lib/constants';

@Component({
  selector: 'app-category-select',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormlyModule,
    MatButtonModule,
    FormlyMatDatepickerModule
  ],
  providers: [CategoryClient],
  templateUrl: './category-select.component.html',
  styleUrls: ['./category-select.component.scss'],
})
export class CategorySelectComponent {
  @Output() onCategoriesReceived = new EventEmitter<CategoryDto[]>();
  @Output() onCategorySelect = new EventEmitter<CategoryDto>();

  @Input() categoryId: string | undefined;
  @Input() categoryType: CategoryType;

  isHardwareOnly: boolean;
  isChecklistOnly: boolean

  currentCategory: CategoryDto | null = null;
  categories$: Observable<Array<CategoryDto>> = this.companyClient.getCategories()
    .pipe(map((categories: CategoryDto[])=> {

      if (this.categoryId) {
        categories.every((category: CategoryDto) => {
          if (category.id === this.categoryId) {
            this.model = category;
            setTimeout(() => {
              this.onSelection();
            }, 100);
            return false;
          } else {
            return true;
          }
        });
      }

      this.isHardwareOnly = !!!categories.find((category: CategoryDto) => category.type === CHECKLIST_TYPE);
      this.isChecklistOnly = !!!categories.find((category: CategoryDto) => category.type === HARDWARE_TYPE);
      this.onCategoriesReceived.emit(categories);
      return categories.filter((category) => category.type === this.categoryType);
    })
    );

  model: CategoryDto = {};

  constructor(
    private companyClient: CompanyClient,
    private categoryClient: CategoryClient
  ) {}

  onSelection() {
    this.onCategorySelect.emit(this.model);
    this.categoryClient.chooseCategory(this.model)
      .subscribe((_result: boolean | null) => {});
  }
}
