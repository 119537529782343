import { Component } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { CategoryDto, CompanyClient, HardwareConnectorDto, TestPlantDto } from 'src/app/lib/api/api.client';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs';
import { HardwareConnectorsListComponent } from '../hardware-connectors-list/hardware-connectors-list.component';

@Component({
  selector: 'app-test-plants-form',
  standalone: true,
  imports: [
    CommonModule, 
    TranslateModule,
    ReactiveFormsModule, 
    FormlyModule, 
    MatButtonModule,
    HardwareConnectorsListComponent
  ],
  templateUrl: './test-plants-form.component.html',
  styleUrls: ['./test-plants-form.component.scss'],
})
export class TestPlantsFormComponent {
  form = new FormGroup({});
  model = {} as TestPlantDto;
  hardwareConnectors: HardwareConnectorDto[] | undefined;
  fields: FormlyFieldConfig[] = [
    {
      key: 'id'
    },
    {
      key: 'name',
      type: 'input',
      props: {
        label: this.translateService.instant('testPlant.name'),
        required: true,
      },
    },
    {
      key: 'categoryId',
      /*
      type: 'select',
      props: {
        label: this.translateService.instant('testPlant.categoryName'),
        required: true,
        options: this.getCategories(),
      },
      */
    },
  ];

  constructor(
    private location: Location, 
    private companyClient: CompanyClient,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.model = this.location.getState() as TestPlantDto;

    this.companyClient.getHardwareConnectorsTestPlant(this.model).subscribe((hardwareConnectors: HardwareConnectorDto[]) => {
      this.hardwareConnectors = hardwareConnectors;
    });
  }

  onSubmit() {
    this.companyClient.postTestPlant(this.form.value).subscribe(() => {
      this.location.back();
    })
  }

  private getCategories(): any {
    const data = this.companyClient.getCategories().pipe(
      map((items: CategoryDto[])=> {
        return items.map((item: CategoryDto) => {
          return { value: item.id, label: item.name };
        });
      })
    );
    return data;
  }
}
