import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BehaviorSubject, Observable, Subject, map, switchMap } from 'rxjs';
import { DeserializeArray, JsonArray } from 'cerializr';
import { Project } from './project.model';
import { TableComponent } from 'src/app/lib/components/table/table.component';
import { MatButtonModule } from '@angular/material/button';
import { Router, RouterModule } from '@angular/router';
import { CompanyClient, ProjectDto } from 'src/app/lib/api/api.client';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { DeleteConfirmComponent } from 'src/app/atom/delete-confirm/delete-confirm.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-projects-list',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    TableComponent, 
    MatButtonModule, 
    RouterModule, 
    MatDialogModule
  ],
  templateUrl: './projects-list.component.html',
  styleUrls: ['./projects-list.component.scss'],
})
export class ProjectsListComponent {
  private notification$: Subject<null> = new BehaviorSubject<null>(null);

  private originalProjects: ProjectDto[];

  projects$: Observable<Project[]> = this.companyClient
    .getProjects().pipe(map((res) => {
      this.originalProjects = res;
      return DeserializeArray(res as JsonArray, Project);
    }));

  data$ = this.notification$.pipe(switchMap(() => this.projects$));
  
  constructor(private router: Router, private companyClient: CompanyClient, public dialog: MatDialog) {}
  
  onDelete(entry: Project) {
    const dialogRef = this.dialog.open(DeleteConfirmComponent);
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.companyClient.deleteProject(entry).subscribe(() => {
          this.notification$.next(null);
        });
      }
    });
  }

  onEdit(entry: Project) {
    const item = this.originalProjects.find((item: ProjectDto) => item.id === entry.id);
    this.router.navigate(['/projects/edit'], { state: item });
  }
}
