import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from 'src/app/lib/components/table/table.component';
import { BehaviorSubject, Observable, Subject, map, of, switchMap } from 'rxjs';
import { TestPlant } from './test-plant.model';
import { DeserializeArray, JsonArray } from 'cerializr';
import { CompanyClient, TestPlantDto } from 'src/app/lib/api/api.client';
import { MatButtonModule } from '@angular/material/button';
import { Router, RouterModule } from '@angular/router';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { DeleteConfirmComponent } from 'src/app/atom/delete-confirm/delete-confirm.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-test-plants-list',
  standalone: true,
  imports: [
    CommonModule, 
    TranslateModule,
    TableComponent, 
    MatButtonModule, 
    RouterModule, 
    MatDialogModule],
  templateUrl: './test-plants-list.component.html',
  styleUrls: ['./test-plants-list.component.scss'],
})
export class TestPlantsListComponent implements OnInit {
  @Input() categoryId: string | undefined;
  @Input() data: TestPlantDto[] | undefined;

  private originalTestPlants: TestPlantDto[] | undefined;

  private notification$: Subject<null> = new BehaviorSubject<null>(null);

  testPlants$: Observable<TestPlant[]>;
  data$: Observable<TestPlant[]>;

  /*
  testPlants$: Observable<TestPlant[]> = this.companyClient
    .getTestPlants()
    .pipe(map((res) => {
      this.originalTestPlants = res;
      return DeserializeArray(res as JsonArray, TestPlant)
    }));

  data$ = this.notification$.pipe(switchMap(() => this.testPlants$));
  */

  constructor(private router: Router, private companyClient: CompanyClient, public dialog: MatDialog) {}

  ngOnInit() {
    this.initData();
  }

  initData() {
    this.originalTestPlants = this.data;
    this.testPlants$ = of(DeserializeArray(this.data as JsonArray, TestPlant));
    this.data$ = this.notification$.pipe(switchMap(() => this.testPlants$));
  }

  onDelete(entry: TestPlantDto) {
    const dialogRef = this.dialog.open(DeleteConfirmComponent);
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.data?.splice(this.data?.indexOf(entry), 1);
        this.initData();
        this.companyClient.deleteTestPlant(entry).subscribe(() => {
          this.notification$.next(null);
        });
      }
    });
  }
  
  onEdit(entry: TestPlant) {
    const item = this.originalTestPlants?.find((item: TestPlantDto) => item.id === entry.id);
    this.router.navigate(['/test-plants/edit'], { state: item });
  }

  onAdd() {
    this.router.navigate(['/test-plants/add'], { state: {categoryId: this.categoryId} });
  }
}
