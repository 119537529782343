import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TestPlantsPageRoutingModule } from './test-plants-page-routing.module';
import { TestPlantsPageComponent } from './test-plants-page.component';


@NgModule({
  declarations: [
    TestPlantsPageComponent
  ],
  imports: [
    CommonModule,
    TestPlantsPageRoutingModule
  ]
})
export class TestPlantsPageModule { }
