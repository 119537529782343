import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlayerMainComponent } from './player-main.component';
import { MessageComponent } from 'src/app/atom/message/message.component';
import { ImageGalleryModule } from 'src/app/molecules/image-gallery/image-gallery.module';
import { HardwareStatusComponent } from 'src/app/atom/hardware-status/hardware-status.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    PlayerMainComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MessageComponent,
    HardwareStatusComponent,
    ImageGalleryModule
  ],
  exports: [
    PlayerMainComponent
  ]
})
export class PlayerMainModule { }
