import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from 'src/app/lib/components/table/table.component';
import { BehaviorSubject, Observable, Subject, map, switchMap } from 'rxjs';
import { Category } from './category.model';
import { DeserializeArray, JsonArray } from 'cerializr';
import { CategoryDto, CompanyClient } from 'src/app/lib/api/api.client';
import { MatButtonModule } from '@angular/material/button';
import { Router, RouterModule } from '@angular/router';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { DeleteConfirmComponent } from 'src/app/atom/delete-confirm/delete-confirm.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-categories-list',
  standalone: true,
  imports: [
    CommonModule, 
    TranslateModule,
    TableComponent, 
    MatButtonModule, 
    RouterModule, 
    MatDialogModule],
  templateUrl: './categories-list.component.html',
  styleUrls: ['./categories-list.component.scss'],
})
export class CategoriesListComponent {
  private notification$: Subject<null> = new BehaviorSubject<null>(null);

  private originalCategories: CategoryDto[];

  categories$: Observable<Category[]> = this.companyClient
    .getCategories()
    .pipe(map((res) => {
      this.originalCategories = res;
      return DeserializeArray(res as JsonArray, Category);
    }));

  data$ = this.notification$.pipe(switchMap(() => this.categories$));

  constructor(private router: Router, private companyClient: CompanyClient, public dialog: MatDialog) {}

  onDelete(entry: CategoryDto) {
    const dialogRef = this.dialog.open(DeleteConfirmComponent);
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.companyClient.deleteCategory(entry).subscribe(() => {
          this.notification$.next(null);
        });
      }
    });
  }
  
  onEdit(entry: Category) {
    const item = this.originalCategories.find((item: CategoryDto) => item.id === entry.id);
    this.router.navigate(['/categories/edit'], { state: item });
  }
}
