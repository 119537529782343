<div class="card">
  <h3>{{ 'navbar.profile' | translate }}</h3>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
    <button type="submit" mat-flat-button color="primary">{{ 'form.save' | translate }}</button>
  </form>

  <br>

  <div>
    <h3>{{ 'common.requestSupport' | translate }} <a href="mailto:{{supportEmail}}">{{supportEmail}}</a></h3>
  </div>

  <app-language-toggle
  [selectedLanguage$]="language$"
  (switchLanguage)="onLanguageChange($event)"
  ></app-language-toggle>
</div>