import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription, interval, map, mergeMap, of } from 'rxjs';
import { DeleteConfirmComponent } from 'src/app/atom/delete-confirm/delete-confirm.component';
import { CategoryDto, CategoryType, ChooseProjectResult, FavoriteDto, ProgressStepClient, ProgressStepDto, ReportConfigurationDto, TestPlantDto, WebServiceType } from 'src/app/lib/api/api.client';
import { ProjectSelectComponent } from 'src/app/organisms/configuration/project-select/project-select.component';
import { ReportSelectComponent } from 'src/app/organisms/configuration/report-select/report-select.component';
import { PlayerPageComponent } from './player-page/player-page.component';
import { WebServiceSelectComponent } from 'src/app/organisms/configuration/webservice-select/webservice-select.component';
import { CATEGORY_NONE, CHECKLIST_TYPE, DISABLED_STATE, GET_PROJECT, GUID_EMPTY, HARDWARE_TYPE, INVISIBLE_STATE, LOGGED_IN_REDIRECT_PATH, POST_REPORT, START_BUTTON, TESTING_FLOW_VIEW_STATE } from 'src/app/lib/constants';
import { CategorySelectComponent } from 'src/app/organisms/configuration/category-select/category-select.component';
import { UserService } from 'src/app/lib/service/user.service';
import { SignalRService } from 'src/app/lib/service/signalr.service';

interface TestingFlowViewState {
  category: CategoryDto;
  testPlant: TestPlantDto;
  projectResult: ChooseProjectResult;
  report: ReportConfigurationDto;
}

enum StepType {
  CATEGORY_SELECT = 1,
  HARDWARE_SELECT = 2,
  PROJECT_SELECT = 3,
  REPORT_SELECT = 4,
  PLAYER = 5,
  REPORT = 6
};

@Component({
  selector: 'app-testing-flow',
  templateUrl: './testing-flow.component.html',
  styleUrls: ['./testing-flow.component.scss']
})
export class TestingFlowComponent implements OnInit, OnDestroy {
  @ViewChild('stepper') stepper!: MatStepper;

  @ViewChild(CategorySelectComponent) catgorySelectComponent: CategorySelectComponent;
  @ViewChild(ProjectSelectComponent) projectSelectComponent: ProjectSelectComponent;
  @ViewChild(WebServiceSelectComponent) webServiceSelectComponent: WebServiceSelectComponent;
  @ViewChild(ReportSelectComponent) reportSelectComponent: ReportSelectComponent;
  @ViewChild(PlayerPageComponent) playerPageComponent: PlayerPageComponent;

  step = StepType;

  categoryType: CategoryType;

  categoryId: string | undefined;
  testPlantId: string | undefined;

  buttonDisabled = DISABLED_STATE;
  buttonInvisible = INVISIBLE_STATE;

  readonly GUID_EMPTY = GUID_EMPTY

  progressStep: ProgressStepDto = <ProgressStepDto>{
    currentProgressStep: 1,
    continueButtonState: this.buttonDisabled,
    stopButtonState: this.buttonDisabled
  };

  progressStepSubject = new BehaviorSubject<ProgressStepDto>(this.progressStep);
  progressStep$ = this.progressStepSubject.asObservable();

  viewState: TestingFlowViewState = {} as TestingFlowViewState;

  GET_PROJECT = GET_PROJECT;
  POST_REPORT = POST_REPORT;

  HARDWARE = HARDWARE_TYPE;
  CHECKLIST = CHECKLIST_TYPE;

  private readonly subscriptions = new Subscription();

  constructor (
    private router: Router,
    private location: Location,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private progressClient: ProgressStepClient,
    private userService: UserService,
    private signalRService: SignalRService
  ) {}

  setViewState(viewState: TestingFlowViewState = this.viewState): void {
    localStorage.setItem(TESTING_FLOW_VIEW_STATE, JSON.stringify(viewState));
  }

  getViewState(): TestingFlowViewState {
    return JSON.parse(localStorage.getItem(TESTING_FLOW_VIEW_STATE) ?? '{}');
  }

  resetViewState(): void {
    this.setViewState({} as TestingFlowViewState);
  }

  ngOnInit() {

    this.initSignalR();

    var state = this.location.getState() as FavoriteDto;

    if (state?.categoryId) {
      this.categoryId = state.categoryId;
      this.testPlantId = state.testPlantId;

      this.categoryType = state.category?.type || this.HARDWARE;
    } else {
      this.categoryType = this.activatedRoute.snapshot.data['categoryType'];
    }

    this.progressClient.getCurrentProgressStep().subscribe((progressStep: ProgressStepDto) => {
      if (progressStep.categoryType === this.categoryType) {
        this.getCurrentProgressStep().subscribe();
      } else if (progressStep.categoryType === CATEGORY_NONE) {
        this.progressClient.createProcessHandler(this.categoryType).subscribe((progressStep: ProgressStepDto) => {
          if (progressStep.categoryType) {
            this.userService.setRunningCategoryType(progressStep.categoryType);
            this.getCurrentProgressStep().subscribe();
          } else {
            throw Error('view not allowed to open without categoryType');
          }
        });
      } else {
        throw Error('view not allowed to open with this categoryType');
      }
    });
  }

  ngOnDestroy(): void {
    this.progressStepSubject.complete();
    this.subscriptions.unsubscribe();
    this.signalRService.disconnect();
  }

  initSignalR(): void {
      this.signalRService.startConnection();
        
      this.subscriptions.add(
        this.signalRService.progressStepChanged$.asObservable().subscribe(() => {
          this.getCurrentProgressStep().subscribe();
        })
      );
  }

  getStepIndex(type: StepType): number {
    if (!this.viewState.category) {
      return type;
    }
    switch (this.viewState.category.type) {
      case this.HARDWARE:
        return type;
      case this.CHECKLIST:
        return type - 1; // without hardware select
      default:
        throw new Error('not implemented')
    }
  }

  getWebServices(type: WebServiceType) {
    return this.viewState.category?.webServices?.filter((item) => item.type === type);
  }

  onCategoriesReceived(categories: CategoryDto[]) {
    // do nothing 
  }

  onCategorySelect(category: CategoryDto) {
    this.viewState.category = category;
    this.setViewState();

    if (this.categoryId) {
      setTimeout(() => {
        this.nextClicked();
      }, 100);
    }
  }

  onTestPlantSelect(testPlant: TestPlantDto) {
    this.viewState.testPlant = testPlant;
    this.setViewState();

    if (this.testPlantId) {
      setTimeout(() => {
        this.nextClicked();
      }, 100);
    }
  }

  onProjectResult(projectResult: ChooseProjectResult) {
    this.viewState.projectResult = projectResult;
    this.setViewState();

    setTimeout(() => {
      this.getCurrentProgressStep().subscribe();
    });
  }

  onReportSelect(report: ReportConfigurationDto) {
    this.viewState.report = report;
    this.setViewState();

    setTimeout(() => {
      this.getCurrentProgressStep().subscribe();
    });
  }

  submitProject() {
    if (this.viewState.category?.chooseProjectWebService && this.viewState.category?.chooseProjectWebService != GUID_EMPTY) {
      this.webServiceSelectComponent.onSubmit().subscribe(() => {
        this.nextClicked();
      });
    } else {
      this.projectSelectComponent.onSubmit().subscribe(() => {
        this.nextClicked();
      });
    }
  }

  submitReport() {
    if (this.stepper.selectedIndex+1 === this.getStepIndex(StepType.REPORT_SELECT)) {
      this.reportSelectComponent.onSubmit().subscribe(() => {
        if (this.reportSelectComponent.form.valid) {
          this.nextClicked();
        }
      });
    }
  }

  get isPlayButtonEnabled(): boolean {
    if (this.playerPageComponent && this.playerPageComponent.isPlayButtonEnabled) {
      return true;
    } else {
      return false;
    }
  }

  get isHardwareOnly(): boolean {
    return this.categoryType === HARDWARE_TYPE;
  }

  get isChecklistOnly(): boolean {
    return this.categoryType === CHECKLIST_TYPE;
  }

  startPlayer() {
    if (this.playerPageComponent?.isPlayButtonEnabled) {
      this.playerPageComponent.onPlayerAction(START_BUTTON);
    }
  }

  nextClicked() {
    if (this.stepper.selected) {
      this.stepper.selected.completed = true;
      this.stepper.next();
    }

    this.progressClient.continue().subscribe(() => {
      setTimeout(() => {
        this.getCurrentProgressStep().subscribe();
      });
    });
  }

  stopClicked() {
    const dialogRef = this.dialog.open(DeleteConfirmComponent, {
      data: { 
        title: 'stopDialog.title', 
        content: 'stopDialog.content',
        confirm: 'stopDialog.confirm' 
      },
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.progressClient.stop().subscribe(() => {
          this.resetViewState();
          this.stepper.reset();
          this.userService.setRunningCategoryType(CATEGORY_NONE);
          this.router.navigate([LOGGED_IN_REDIRECT_PATH]);
        });
      }
    });
  }

  isStepCompleted(type: StepType) {
    const index = this.getStepIndex(type);
    const currentProgressStep =this.progressStep?.currentProgressStep

    if (currentProgressStep && index < currentProgressStep) {
      return true;
    }

    return false
  }

  private getCurrentProgressStep(): Observable<ProgressStepDto> {
    return this.progressClient.getCurrentProgressStep()
          .pipe(map((progressStep: ProgressStepDto) => {
              this.progressStepSubject.next(progressStep);
              
              this.progressStep = progressStep;
              if (this.progressStep.currentProgressStep) {
                this.viewState = this.getViewState();
                this.stepper.selectedIndex = this.progressStep.currentProgressStep - 1;
              }
              return progressStep;
            })
          );
  }
}
