import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectClient, VariableDto } from 'src/app/lib/api/api.client';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';

const TrueValue = 'True'
const FalseValue = 'False'

@Component({
  selector: 'app-correction-values',
  standalone: true,
  imports: [
    CommonModule, 
    TranslateModule,
    MatButtonModule,
    MatCheckboxModule,
    FormsModule
  ],
  providers: [ProjectClient],
  templateUrl: './correction-values.component.html',
  styleUrls: ['./correction-values.component.scss']
})
export class CorrectionValuesComponent {  
  @Input() correctionValues: VariableDto[] | undefined;

  getCorrectionValue(variable: VariableDto): boolean {
    return variable.value === TrueValue;
  }

  toggleCorrectionValue(variable: VariableDto) {
    if (variable.value === TrueValue) {
      variable.value = FalseValue;
    } else if (variable.value === FalseValue) {
      variable.value = TrueValue;
    }
  }

  isTextInput(variable: VariableDto) {
    return variable?.name?.startsWith('MS');
  }
}
