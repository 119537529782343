import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BaseService } from './base.service';
import {
  AuthClient,
  AuthResponseDto,
  UserForAuthenticationDto,
} from '../api/api.client';
import { AuthStorageService } from './auth-storage.service';

import jwt_decode from 'jwt-decode';
import { UserService } from './user.service';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  public loggedIn$ = new BehaviorSubject(this.authStorage.isLoggedIn());

  constructor(
    private router: Router,
    private authStorage: AuthStorageService,
    private userService: UserService,
    private authClient: AuthClient,
    private dataService: DataService
  ) {
    super();
  }

  public get storage(): AuthStorageService {
    return this.authStorage;
  }

  login(
    email: string | null | undefined,
    password: string | null | undefined
  ): Observable<AuthResponseDto> {
    return this.authClient
      .login({ email, password } as UserForAuthenticationDto)
      .pipe(
        map((response) => {
          this.handleLoginSuccess(response);
          this.dataService.getInitialData();
          return response;
        })
      );
  }

  private handleLoginSuccess(response: AuthResponseDto): void {
    if (response.token) {
      const parsedJwt = this.parseJwt(response.token) as any;
      const role = parsedJwt['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
      const name = parsedJwt['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];
      const language = parsedJwt['language'];
      const zoomFactor = parsedJwt['zoomFactor'];

      this.loggedIn$.next(true);

      this.userService.setName(name);
      this.userService.setRole(role);
      this.userService.setLanguage(language);
      this.userService.setZoomFactor(zoomFactor);

      this.storage.setToken(response.token);
    }
  }

  private parseJwt(token: string) {
    return jwt_decode(token);
  }

  logout() {
    this.storage.logout();
    this.loggedIn$.next(false);
    this.router.navigate(['/auth/login']);
  }
}
