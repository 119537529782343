import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WebServicesPageComponent } from './webservices-page.component';
import { WebServicesListComponent } from 'src/app/organisms/crud/webservices-list/webservices-list.component';
import { WebServicesFormComponent } from 'src/app/organisms/crud/webservices-form/webservices-form.component';

const routes: Routes = [{
  path: '',
  component: WebServicesPageComponent,
  children: [
    {
      path: '',
      component: WebServicesListComponent
    },
    {
      path: 'add',
      component: WebServicesFormComponent
    },
    {
      path: 'edit',
      component: WebServicesFormComponent
    }
  ]
},];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WebServicesPageRoutingModule { }
