import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FileEventTarget } from '../message/message.component';
import { TABLE_OUTPUT_BARCHART, TABLE_OUTPUT_TABLE } from 'src/app/lib/constants';
import { TableOutputOptionType } from 'src/app/lib/api/api.client';

export interface ImportTableData {
  fileEvent: FileEventTarget;
  sheetName: string;
  cellRange: string;
  tableOutputOptions: TableOutputOptionType[];
  argumentColumn: string;
  valueColumn: string;
};

@Component({
  selector: 'app-import-table',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    MaterialFileInputModule,
    MatFormFieldModule,
    MatDialogModule, 
    MatButtonModule,
    MatIconModule,
    FormlyModule,
  ],
  templateUrl: './import-table.component.html',
  styleUrls: ['./import-table.component.scss']
})
export class ImportTableComponent {
  model = {};

  form: FormGroup = this.fb.group({
    fileEvent: [null, Validators.required],
    sheetName: ['', Validators.required],
    cellRange: ['', Validators.required],
    tableOutputOptions: [[], Validators.required],
    tableOutputTable: [''],
    tableOutputBarchart: [''],
    argumentColumn: [''],
    valueColumn: [''],
  });

  fields: FormlyFieldConfig[] = [
    {
      key: 'sheetName',
      type: 'input',
      props: {
        label: this.translateService.instant('import.table.sheetName'),
        required: true,
      },
    },
    {
      key: 'cellRange',
      type: 'input',
      props: {
        label: this.translateService.instant('import.table.cellRange'),
        required: true,
      },
    },
    {
      key: 'tableOutputTable',
      type: 'checkbox',
      props: {
        label: 'Table',
        labelPosition: 'after',
        indeterminate: false,
      },
    },
    {
      key: 'tableOutputBarchart',
      type: 'checkbox',
      props: {
        label: 'Barchart',
        labelPosition: 'after',
        indeterminate: false,
      },
    },
    {
      key: 'argumentColumn',
      type: 'input',
      props: {
        label: this.translateService.instant('import.table.argumentColumn'),
        required: true,
      },
      expressions: { hide: '!model.tableOutputBarchart' },
    },
    {
      key: 'valueColumn',
      type: 'input',
      props: {
        label: this.translateService.instant('import.table.valueColumn'),
        required: true,
      },
      expressions: { hide: '!model.tableOutputBarchart' },
    },
  ];

  constructor(
    private fb: FormBuilder, 
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<ImportTableComponent>,
    @Inject(MAT_DIALOG_DATA) data: { tableData: ImportTableData }
  ) {
    if (data.tableData) {
      this.form.controls['fileEvent'].setValue(data.tableData.fileEvent);
      this.form.controls['sheetName'].setValue(data.tableData.sheetName);
      this.form.controls['cellRange'].setValue(data.tableData.cellRange);
      this.form.controls['tableOutputOptions'].setValue(data.tableData.tableOutputOptions);

      if (data.tableData.tableOutputOptions.find((option) => (option === TABLE_OUTPUT_TABLE))) {
        this.form.controls['tableOutputTable'].setValue(true);
      }

      if (data.tableData.tableOutputOptions.find((option) => (option === TABLE_OUTPUT_BARCHART))) {
        this.form.controls['tableOutputBarchart'].setValue(true);
        this.form.controls['argumentColumn'].setValue(data.tableData.argumentColumn);
        this.form.controls['valueColumn'].setValue(data.tableData.valueColumn);
      }

      this.model = data.tableData;
    }
  }

  onDismiss() {
    this.dialogRef.close();
  }

  onConfirm() {
    const tableOutputOptions = [];
    if (this.form.get('tableOutputTable')?.value === true) {
      tableOutputOptions.push(TABLE_OUTPUT_TABLE);
    }
    if (this.form.get('tableOutputBarchart')?.value === true) {
      tableOutputOptions.push(TABLE_OUTPUT_BARCHART);
    }
    this.form.get('tableOutputOptions')?.setValue(tableOutputOptions);
    this.dialogRef.close(this.form.value);
  }
}
