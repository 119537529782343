import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserClient, UserDto } from 'src/app/lib/api/api.client';
import { BehaviorSubject, Observable, Subject, map, switchMap } from 'rxjs';
import { DeserializeArray, JsonArray } from 'cerializr';
import { User } from './user.model';
import { TableComponent } from 'src/app/lib/components/table/table.component';
import { MatButtonModule } from '@angular/material/button';
import { Router, RouterModule } from '@angular/router';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { DeleteConfirmComponent } from 'src/app/atom/delete-confirm/delete-confirm.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-users-list',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    TableComponent, 
    MatButtonModule, 
    RouterModule, 
    MatDialogModule
  ],
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent {
  private notification$: Subject<null> = new BehaviorSubject<null>(null);
  users$: Observable<User[]> = this.userClient.getUsers().pipe(map((res) => DeserializeArray(res as JsonArray, User)));
  data$ = this.notification$.pipe(switchMap(() => this.users$));
  
  constructor(private router: Router, private userClient: UserClient, public dialog: MatDialog) {}
  
  onDelete(entry: UserDto) {
    const dialogRef = this.dialog.open(DeleteConfirmComponent);
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.userClient.deleteUser(entry).subscribe(() => {
          this.notification$.next(null);
        });
      }
    });
  }

  onEdit(entry: UserDto) {
    this.router.navigate(['/users/edit'], { state: entry });
  }
}
