import { Component } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { CompanyClient, LibraryDto, ProblemDetails } from 'src/app/lib/api/api.client';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FileInputComponent } from 'src/app/molecules/file-input/file-input.component';

@Component({
  selector: 'app-libraries-form',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormlyModule,
    MatButtonModule,
    MatFormFieldModule,
    FileInputComponent
  ],
  templateUrl: './libraries-form.component.html',
  styleUrls: ['./libraries-form.component.scss'],
})
export class LibrariesFormComponent {
  form = new FormGroup({});
  model = {} as LibraryDto;
  fields: FormlyFieldConfig[] = [
    {
      key: 'id'
    },
    {
      key: 'name',
      type: 'input',
      hide: true,
    },
    {
      key: 'version',
      type: 'input',
      hide: true,
    },
  ];
  
  constructor(
    private router: Router,
    private snack: MatSnackBar,
    private location: Location, 
    private companyClient: CompanyClient,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.model = this.location.getState() as LibraryDto;
    this.form.addControl('fileData', new FormControl(this.model.fileData, Validators.required));
  }

  async onSubmit() {
    const dto = <LibraryDto>{
      ...this.form.value,
    };
    this.companyClient.postLibrary(dto).subscribe(() => {
      this.router.navigate(["/libraries"]);
    }, (err: ProblemDetails) => {
      this.snack.open(`${this.translateService.instant('project.missingLibraries')}\n ${err.detail}`);
    });
  }

  updateFileData(fileData: string) {
    this.form.patchValue({fileData: fileData})
  }
}
