import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SettingsStorageService implements OnDestroy {
  public readonly zoomFactor$: BehaviorSubject<number> = new BehaviorSubject(this.getZoomFactor());
  public readonly language$: BehaviorSubject<string> = new BehaviorSubject(this.getLanguage());

  ngOnDestroy(): void {
      this.zoomFactor$.complete();
      this.language$.complete();
  }

  setZoomFactor(zoomFactor: string): void {
    localStorage.setItem('zoomFactor', JSON.stringify(zoomFactor));
    if (zoomFactor) {
      this.zoomFactor$.next(parseInt(zoomFactor));
    }
  }

  getZoomFactor(): number {
    const zoomFactor = JSON.parse(localStorage.getItem('zoomFactor') as string);

    if (zoomFactor) {
      return parseInt(zoomFactor);
    }
    return 100;
  }

  setLanguage(lang: string): void {
    localStorage.setItem('lang', JSON.stringify(lang));
    this.language$.next(lang);
  }

  getLanguage(): string {
    const lang = JSON.parse(localStorage.getItem('lang') as string);

    if (lang) {
      return lang;
    }
    return 'en';
  }
}
