<h3>{{ 'projectSelect.title' | translate }}</h3>
<h2 *ngIf="chooseProjectResult?.status === NOT_EXECUTABLE" class="warn">
  {{ 'projectSelect.notExecutable' | translate: { 
    name: chooseProjectResult?.name ?? '', version: chooseProjectResult?.version ?? '' } 
  }}
</h2>
<ul>
  <ng-container *ngFor="let project of projects$ | async">
    <li [class.selected]="project === currentProject" (click)="setProject(project)">
      {{ project.name }} ({{ project.version }})
    </li>
    <app-correction-values *ngIf="project === currentProject && chooseProjectResult?.correctionValues?.length" 
      [correctionValues]="chooseProjectResult?.correctionValues"></app-correction-values>
  </ng-container>
</ul>
