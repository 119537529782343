import { ButtonState, ButtonType, CategoryType, ChooseProjectStatus, InterfaceVariableType, Severity, TableOutputOptionType, WebServiceType } from "./api/api.client";

export const LOGGED_IN_REDIRECT_PATH = '/home';

export const GUID_EMPTY = '00000000-0000-0000-0000-000000000000';

export const TESTING_FLOW_VIEW_STATE = 'testing-flow-view-state';

export const NOT_FOUND = ChooseProjectStatus._2;
export const NOT_EXECUTABLE_ON_TEST_PLANT = ChooseProjectStatus._3;
export const MISSING_LIBRARY = ChooseProjectStatus._4;

export const START_BUTTON = ButtonType._0;
export const STOP_BUTTON = ButtonType._1;
export const REPEAT_BUTTON = ButtonType._2;
export const SKIP_BUTTON = ButtonType._3;
export const CONFIRM_BUTTON = ButtonType._4;

export const ENABLED_STATE = ButtonState._0;
export const DISABLED_STATE = ButtonState._1;
export const INVISIBLE_STATE = ButtonState._2;

export const CATEGORY_NONE = CategoryType._0;
export const HARDWARE_TYPE = CategoryType._1;
export const CHECKLIST_TYPE = CategoryType._2;

export const GET_PROJECT = WebServiceType._0;
export const POST_PROJECT = WebServiceType._1;
export const POST_REPORT = WebServiceType._2;

export const SEVERITY_INFO = Severity._0;
export const SEVERITY_SUCCESS = Severity._1;
export const SEVERITY_ERROR = Severity._2;
export const SEVERITY_SUMMARY = Severity._3;
export const SEVERITY_PENDING = Severity._4;
export const SEVERITY_USER_DEFINED = Severity._5;

export const TABLE_OUTPUT_NONE = TableOutputOptionType._0;
export const TABLE_OUTPUT_TABLE = TableOutputOptionType._1;
export const TABLE_OUTPUT_BARCHART = TableOutputOptionType._2;

export const VARIABLE_TYPE_NONE = InterfaceVariableType._0;
export const VARIABLE_TYPE_HARDWARE_INPUT = InterfaceVariableType._1;
export const VARIABLE_TYPE_HARDWARE_OUTPUT = InterfaceVariableType._2;
export const VARIABLE_TYPE_TEXT_INPUT = InterfaceVariableType._3;
