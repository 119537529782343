import { Component } from '@angular/core';

@Component({
  selector: 'app-hardware-connectors-page',
  templateUrl: './hardware-connectors-page.component.html',
  styleUrls: ['./hardware-connectors-page.component.scss']
})
export class HardwareConnectorsPageComponent {

}
