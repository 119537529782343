import { Component, OnInit } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { MatButtonModule } from '@angular/material/button';
import { CompanyClient, HardwareConnectorDto, TestPlantDto } from 'src/app/lib/api/api.client';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs';

@Component({
  selector: 'app-hardware-connectors-form',
  standalone: true,
  imports: [
    CommonModule, 
    TranslateModule,
    ReactiveFormsModule, 
    FormlyModule, 
    MatButtonModule
  ],
  providers: [Location],
  templateUrl: './hardware-connectors-form.component.html',
  styleUrls: ['./hardware-connectors-form.component.scss'],
})
export class HardwareConnectorsFormComponent implements OnInit {
  form = new FormGroup({});
  model = {} as HardwareConnectorDto;
  fields: FormlyFieldConfig[] = [
    {
      key: 'id'
    },
    {
      key: 'name',
      type: 'input',
      props: {
        label: this.translateService.instant('hardwareConnector.name'),
        required: true,
      },
    },
    {
      key: 'hardwareId',
      type: 'input',
      props: {
        label: this.translateService.instant('hardwareConnector.hardwareId'),
        required: true,
      },
    },
    {
      key: 'testPlantId',
      /*
      type: 'select',
      props: {
        label: this.translateService.instant('hardwareConnector.testPlantName'),
        required: true,
        options: this.getTestPlants(),
      },
      */
    },
  ];

  constructor(
    private location: Location, 
    private companyClient: CompanyClient,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.model = this.location.getState() as HardwareConnectorDto;
  }

  onSubmit() {
    this.companyClient.postHardwareConnector(this.form.value).subscribe(() => {
      this.location.back();
    })
  }

  private getTestPlants(): any {
    const data = this.companyClient.getTestPlants().pipe(
      map((items: TestPlantDto[])=> {
        return items.map((item: TestPlantDto) => {
          return { value: item.id, label: item.name };
        });
      })
    );
    return data;
  }
}
