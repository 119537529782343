import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  BehaviorSubject,
  Observable,
  Subject,
  of,
  switchMap,
} from 'rxjs';
import { HardwareConnector } from './hardware-connector.model';
import { DeserializeArray, JsonArray } from 'cerializr';
import { TableComponent } from 'src/app/lib/components/table/table.component';
import { MatButtonModule } from '@angular/material/button';
import { Router, RouterModule } from '@angular/router';
import {
  CompanyClient, HardwareConnectorDto,
} from 'src/app/lib/api/api.client';
import { DeleteConfirmComponent } from 'src/app/atom/delete-confirm/delete-confirm.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-hardware-connectors-list',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    TableComponent,
    MatButtonModule,
    RouterModule,
    MatDialogModule,
    MatIconModule
  ],
  templateUrl: './hardware-connectors-list.component.html',
  styleUrls: ['./hardware-connectors-list.component.scss'],
})
export class HardwareConnectorsListComponent {
  @Input() testPlantId: string | undefined;
  @Input() data: HardwareConnectorDto[] | undefined;

  private notification$: Subject<null> = new BehaviorSubject<null>(null);

  HardwareConnectors$: Observable<HardwareConnectorDto[]>;
  data$: Observable<HardwareConnectorDto[]>;

  /*
  private HardwareConnectors$: Observable<HardwareConnector[]> =
    this.companyClient
      .getHardwareConnectors()
      .pipe(
        map((res) => DeserializeArray(res as JsonArray, HardwareConnector))
      );

  data$ = this.notification$.pipe(switchMap(() => this.HardwareConnectors$));
  */

  constructor(
    private router: Router,
    private companyClient: CompanyClient,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.initData();
  }

  initData() {
    this.HardwareConnectors$ = of(DeserializeArray(this.data as JsonArray, HardwareConnector));
    this.data$ = this.notification$.pipe(switchMap(() => this.HardwareConnectors$));
  }

  onDelete(entry: HardwareConnector) {
    const dialogRef = this.dialog.open(DeleteConfirmComponent);
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.data?.splice(this.data?.indexOf(entry), 1);
        this.initData();
        this.companyClient.deleteHardwareConnector(entry).subscribe(() => {
          this.notification$.next(null);
        });
      }
    });
  }

  onEdit(entry: HardwareConnector) {
    this.router.navigate(['/hardware-connectors/edit'], { state: entry });
  }

  onAdd() {
    this.router.navigate(['/hardware-connectors/add'], { state: {testPlantId: this.testPlantId} });
  }
}
