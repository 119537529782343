<h1 mat-dialog-title>{{ 'import.image.title' | translate }}
  <button class="close" mat-mini-fab color="primary" (click)="onDismiss()">
    <mat-icon>close</mat-icon>
  </button>
</h1>

<div mat-dialog-content class="dialog-content">

  <div [formGroup]="form">
    <div formArrayName="images" class="image-container">
      <ng-container *ngFor="let image of images.controls; let i = index">
        <div [formGroupName]="i">
          <div class="center-cropped" [ngStyle]="{'background-image': 'url(' + image.get('fileData')?.value + ')'}">
            <button class="delete" mat-mini-fab color="primary" (click)="removeImage(i)">
              <mat-icon>delete_forever</mat-icon>
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button type="button" mat-stroked-button class="file-container pointer">
    <input type="file" #fileRef accept="image/*" multiple (change)="addImage($event)" class="file-hidden" />
    <mat-icon class="image-search">image_search</mat-icon> {{ 'import.image.add' | translate }}
  </button>
  <button *ngIf="images.length" mat-flat-button color="primary" mat-dialog-close cdkFocusInitial (click)="onConfirm()">
    {{ 'import.confirm' | translate }}
  </button>
</div>