import { Component } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { CategoryDto, CompanyClient, WebServiceConfigurationDto, WebServiceType } from 'src/app/lib/api/api.client';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { WebServicesListComponent } from '../webservices-list/webservices-list.component';
import { CHECKLIST_TYPE, GUID_EMPTY, HARDWARE_TYPE } from 'src/app/lib/constants';
import { TestPlantsListComponent } from '../test-plants-list/test-plants-list.component';

@Component({
  selector: 'app-test-plants-form',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormlyModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    WebServicesListComponent,
    MatTabsModule,
    TestPlantsListComponent,
  ],
  templateUrl: './categories-form.component.html',
  styleUrls: ['./categories-form.component.scss'],
})
export class CategoriesFormComponent {
  form = new FormGroup({});

  model = {} as CategoryDto;

  loading = false;

  GET_PROJECT = WebServiceType._0;
  POST_PROJECT = WebServiceType._1;
  POST_REPORT = WebServiceType._2;

  HARDWARE = HARDWARE_TYPE;

  fields: FormlyFieldConfig[];

  fieldsReport: FormlyFieldConfig[];

  keyValueForm: FormGroup = this.fb.group({
    reportFields: this.fb.array([]),
    reportTitles: this.fb.array([]),
    reportFilenames: this.fb.array([]),
  });

  get reportFields() {
    return this.keyValueForm.get('reportFields') as FormArray;
  }

  get reportTitles() {
    return this.keyValueForm.get('reportTitles') as FormArray;
  }

  get reportFilenames() {
    return this.keyValueForm.get('reportFilenames') as FormArray;
  }

  activeTab: number = 0;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private location: Location,
    private companyClient: CompanyClient,
    private translateService: TranslateService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    const state = this.location.getState() as CategoryDto;
    if (state.id) {
      this.updateWebServices();
    } else {
      this.initViewState();
    }
  }

  updateWebServices() {
    this.loading = true;
    const state = this.location.getState() as CategoryDto;
    this.companyClient.getCategory(state).subscribe((category: CategoryDto) => {
      this.model = category;
      this.initViewState();
      this.loading = false;
    });
  }

  initViewState() {
    this.initFields();
    this.initFieldsReport();
    this.initReportFieldsKeyValue();
    this.initReporTitleTranslation();
    this.initReporFilenameTranslation();

    if (!this.model.chooseProjectWebService) {
      this.model.chooseProjectWebService = GUID_EMPTY;
    }

    this.route.queryParams.subscribe(params => {
      if (params['tab']) {
        this.activeTab = params['tab'];
      }
    });
  }

  initFields() {
    this.fields = [
      {
        key: 'id'
      },
      {
        key: 'name',
        type: 'input',
        props: {
          label: this.translateService.instant('category.name'),
          required: true,
        },
      },
      {
        key: 'type',
        type: 'select',
        props: {
          label: this.translateService.instant('category.type'),
          required: true,
          options: [
            { label: 'Hardware', value: HARDWARE_TYPE },
            { label: 'Checklist', value: CHECKLIST_TYPE },
          ],
        },
      },
      {
        key: 'chooseProjectWebService',
        type: 'select',
        defaultValue: GUID_EMPTY,
        props: {
          label: this.translateService.instant('projectSelect.title'),
          options: this.getChooseProjectWebServiceOptions(),
        },
      },
      {
        key: 'printDetails',
        type: 'checkbox',
        defaultValue: true,
        props: {
          label: this.translateService.instant('category.printDetails'),
          labelPosition: 'after',
          indeterminate: false,
        },
      },
      {
        key: 'printSummary',
        type: 'checkbox',
        props: {
          label: this.translateService.instant('category.printSummary'),
          labelPosition: 'after',
          indeterminate: false,
        },
      },
      {
        key: 'claimConfirmError',
        type: 'checkbox',
        defaultValue: true,
        props: {
          label: this.translateService.instant('category.confirmError'),
          labelPosition: 'after',
          indeterminate: false,
        },
      },
      {
        key: 'reportResultAddOverviewMessage',
        type: 'checkbox',
        defaultValue: true,
        props: {
          label: this.translateService.instant('category.report.overviewResult'),
          labelPosition: 'after',
          indeterminate: false,
        },
      },
      {
        key: 'reportRemoveDuplicatedMessages',
        type: 'checkbox',
        defaultValue: true,
        props: {
          label: this.translateService.instant('category.report.removeDuplicated'),
          labelPosition: 'after',
          indeterminate: false,
        },
      },
      {
        key: 'reportRemoveSkippedMessages',
        type: 'checkbox',
        defaultValue: true,
        props: {
          label: this.translateService.instant('category.report.removeSkipped'),
          labelPosition: 'after',
          indeterminate: false,
        },
      },
    ];
  }

  initFieldsReport() {
    this.fieldsReport = [
      {
        key: 'reportEmails',
        type: 'input',
        props: {
          label: this.translateService.instant('category.reportEmails'),
        },
      },
      {
        key: 'reportEmailButton',
        type: 'input',
        props: {
          label: this.translateService.instant('category.report.emailButton'),
        },
      },
      {
        key: 'reportEmailSubject',
        type: 'input',
        props: {
          label: this.translateService.instant('category.report.emailSubject'),
        },
      },
      {
        key: 'reportEmailBody',
        type: 'input',
        props: {
          label: this.translateService.instant('category.report.emailBody'),
        },
      },
      {
        key: 'reportEmailPdf',
        type: 'checkbox',
        props: {
          label: this.translateService.instant('category.report.sendPdf'),
          labelPosition: 'after',
          indeterminate: false,
        },
      },
      {
        key: 'reportEmailRaw',
        type: 'checkbox',
        props: {
          label: this.translateService.instant('category.report.sendRaw'),
          labelPosition: 'after',
          indeterminate: false,
        },
      },
    ];
  }

  onTabChange(event: MatTabChangeEvent) {
    const index = event.index;
    const state = this.location.getState() as CategoryDto;
    this.router.navigate(
      [this.router.url.split('?')[0]],
      {
        relativeTo: this.route,
        queryParams: { tab: index },
        state
    });
    this.initFields();
  }

  getWebServices(type: WebServiceType) {
    return this.model.webServices?.filter((webService: WebServiceConfigurationDto) => webService.type === type);
  }

  initReportFieldsKeyValue() {
    if (this.model.reportFieldsAsJsonString) {
      Object.entries(JSON.parse(this.model.reportFieldsAsJsonString)).forEach((keyValue: any) => {
        this.addReportFieldKeyValue(keyValue[0], keyValue[1]);
      });
    }
  }

  initReporTitleTranslation() {
    if (this.model.reportTitlesAsJsonString) {
      Object.entries(JSON.parse(this.model.reportTitlesAsJsonString)).forEach((translation: any) => {
        this.addReportTitleTranslation(translation[0], translation[1]);
      });
    }
  }

  initReporFilenameTranslation() {
    if (this.model.reportFilenamesAsJsonString) {
      Object.entries(JSON.parse(this.model.reportFilenamesAsJsonString)).forEach((translation: any) => {
        this.addReportFilenameTranslation(translation[0], translation[1]);
      });
    }
  }

  onSubmit() {
    const reportFields = this.getKeyValues(this.reportFields);
    const reportTitleTranslations = this.getKeyValues(this.reportTitles);
    const reportFilenameTranslations = this.getKeyValues(this.reportFilenames);

    const body = {
      ...this.form.value,
      ...{
        reportFieldsAsJsonString: JSON.stringify(reportFields),
        reportTitlesAsJsonString: JSON.stringify(reportTitleTranslations),
        reportFilenamesAsJsonString: JSON.stringify(reportFilenameTranslations)
      }
    } as CategoryDto

    this.companyClient.postCategory(body).subscribe(() => {
      this.router.navigate(["/categories"]);
    })
  }

  addReportFieldKeyValue(code = '', value = '') {
    const formGroup =  this.fb.group({
      code: code,
      value: value,
    });

    this.reportFields.push(formGroup);
  }

  removeReportFieldKeyValue(index: number) {
    this.reportFields.removeAt(index);
  }

  addReportTitleTranslation(code = '', value = '') {
    const formGroup =  this.fb.group({
      code: code,
      value: value,
    });

    this.reportTitles.push(formGroup);
  }

  removeReportTitleTranslation(index: number) {
    this.reportTitles.removeAt(index);
  }

  addReportFilenameTranslation(code = '', value = '') {
    const formGroup =  this.fb.group({
      code: code,
      value: value,
    });

    this.reportFilenames.push(formGroup);
  }

  removeReportFilenameTranslation(index: number) {
    this.reportFilenames.removeAt(index);
  }

  private getKeyValues(formArray: FormArray<any>) {
    const translations = <any>{};
    formArray.controls.forEach((control) => {
      const code = control.get('code')?.value;
      const value = control.get('value')?.value;
      translations[code] = value;
    });
    return translations;
  }

  private getChooseProjectWebServiceOptions() {
    const options: any = [
      { label: this.translateService.instant('projectSelect.manually'), value: GUID_EMPTY },
    ];

    this.getWebServices(this.GET_PROJECT)?.forEach((webService: WebServiceConfigurationDto) =>  {
      options.push({ label: webService.name, value: webService.id });
    });

    return options;
  }
}
