import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import {
  TestPlantClient,
  TestPlantDto,
} from 'src/app/lib/api/api.client';
import { Observable, map } from 'rxjs';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { MatButtonModule } from '@angular/material/button';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-test-plant-select',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormlyModule,
    MatButtonModule,
    FormlyMatDatepickerModule
  ],
  providers: [TestPlantClient],
  templateUrl: './test-plant-select.component.html',
  styleUrls: ['./test-plant-select.component.scss'],
})
export class TestPlantSelectComponent {
  @Input() testPlantId: string | undefined;
  @Output() onTestPlantSelect = new EventEmitter<TestPlantDto>();

  currentTestPlant: TestPlantDto | null = null;

  testPlants$: Observable<Array<TestPlantDto>> = this.testPlantClient.getTestPlants()
    .pipe(map((testPlants: TestPlantDto[]) => {
      if(this.testPlantId) {
        testPlants.every((testPlant: TestPlantDto) => {
          if (testPlant.id === this.testPlantId) {
            this.model = testPlant;
            setTimeout(() => {
              this.onSelection();
            }, 100);
            return false;
          } else {
            return true;
          }
        });
      }
      return testPlants;
    }));

  model: TestPlantDto = {};

  constructor(
    private testPlantClient: TestPlantClient
  ) {}

  onSelection() {
    this.onTestPlantSelect.emit(this.model);
    this.testPlantClient.chooseTestPlant(this.model)
      .subscribe((_result: boolean | null) => {});
  }
}
