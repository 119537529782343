import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LOGGED_IN_REDIRECT_PATH } from 'src/app/lib/constants';
import { AuthService } from 'src/app/lib/service/auth.service';

@Component({
  selector: 'auth-component',
  template: '<router-outlet></router-outlet>'
})
export class AuthComponent {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.authService.loggedIn$.subscribe((isLoggedIn: boolean) => {
      if (isLoggedIn) {
        this.router.navigate([LOGGED_IN_REDIRECT_PATH]);
      }
    });
  }
}
