import { APP_INITIALIZER, ErrorHandler, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import {
  API_BASE_URL,
  AuthClient,
  CompanyClient,
  PlayerClient,
  ProgressStepClient,
  ReportClient,
  UserClient,
} from '../lib/api/api.client';
import { JwtInterceptor } from '../lib/interceptor/jwt.interceptor';
import { AuthService } from '../lib/service/auth.service';
import { AuthStorageService } from '../lib/service/auth-storage.service';
import { ErrorInterceptor } from '../lib/interceptor/error.interceptor';
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBar,
} from '@angular/material/snack-bar';
import { BlobErrorHttpInterceptor } from '../lib/interceptor/blob.interceptor';
import { VariableStorageService } from '../lib/service/variable-storage.service';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SettingsStorageService } from '../lib/service/settings-storage.service';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyFieldFile } from '../lib/components/file/formly-file.component';
import { FileValueAccessor } from '../lib/components/file/file-value-accessor';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { GlobalErrorHandler } from '../lib/service/global-error-service';
import { environment } from 'src/environments/environment';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormlyFieldAutocomplete } from '../lib/components/formly/formly-autocomplete.component';

import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export const translateModuleConfig = {
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient],
  },
};

export function appInitializerFactory(translate: TranslateService, settingStorageService: SettingsStorageService) {
  return () => {
    const language = settingStorageService.getLanguage();
    translate.setDefaultLang(language);
    return translate.use(language);
  };
}

@NgModule({
  declarations: [
    AppComponent, 
    FileValueAccessor, 
    FormlyFieldFile,
    FormlyFieldAutocomplete
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormlyMaterialModule,
    FormlyModule.forRoot({
      types: [
        { name: 'file', component: FormlyFieldFile, wrappers: ['form-field'] },
        { name: 'autocomplete', component: FormlyFieldAutocomplete, wrappers: ['form-field'] }
      ],
    }),
    MatMomentDateModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    TranslateModule.forRoot(translateModuleConfig),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, SettingsStorageService],
      multi: true
    },
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    MatSnackBar,
    AuthStorageService,
    VariableStorageService,
    SettingsStorageService,
    {
      provide: AuthService,
      useClass: AuthService,
    },
    AuthClient,
    UserClient,
    PlayerClient,
    ReportClient,
    CompanyClient,
    ProgressStepClient,
    {
      provide: API_BASE_URL,
      useValue: environment.apiRoot,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlobErrorHttpInterceptor,
      multi: true,
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 2500 },
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
