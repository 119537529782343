<div class="card">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">

    <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="start" 
      [(selectedIndex)]="activeTab" (selectedTabChange)="onTabChange($event)">

      <mat-tab label="{{ 'category.tabs.general' | translate }}">
        <br>
        <h3 *ngIf="!model.id">{{ 'form.add' | translate }}</h3>
        <h3 *ngIf="model.id">{{ 'form.edit' | translate }}</h3>
        <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>

        <br>
        <br>
        <br>
    
        <button type="submit" mat-flat-button color="primary">{{ 'form.save' | translate }}</button>

        <br>
        <br>
      </mat-tab>

      <mat-tab label="{{ 'navbar.hardwareConnectors' | translate }}" *ngIf="model.type == HARDWARE">
        <app-test-plants-list *ngIf="model.id" [categoryId]="model.id" [data]="model.testPlants"></app-test-plants-list>
      </mat-tab>

      <mat-tab label="{{ 'category.tabs.report' | translate }}">
        <br>
        <h3 *ngIf="!model.id">{{ 'form.add' | translate }}</h3>
        <h3 *ngIf="model.id">{{ 'form.edit' | translate }}</h3>
        <formly-form [form]="form" [fields]="fieldsReport" [model]="model"></formly-form>

        <br>
        <br>
        <br>
    
        <button type="submit" mat-flat-button color="primary">{{ 'form.save' | translate }}</button>

        <br>
        <br>
      </mat-tab>

      <mat-tab label="{{ 'reportSelect.header' | translate }}">
        <br>
        <h3>{{ 'reportSelect.header' | translate }}</h3>
        
        <div [formGroup]="keyValueForm">
          <table formArrayName="reportFields">
            <ng-container *ngFor="let language of reportFields.controls; let i = index">
            <tr [formGroupName]="i">
              <td class="label">
                <mat-form-field>
                  <mat-label>{{ 'category.reportFields.code' | translate }}</mat-label>
                  <input matInput formControlName="code">
                </mat-form-field>
              </td>
              <td class="separator">:</td>
              <td class="value">
                <mat-form-field>
                  <mat-label>{{ 'category.reportFields.value' | translate }}</mat-label>
                  <input matInput formControlName="value">
                </mat-form-field>
              </td>
              <td>
                <button mat-icon-button (click)="removeReportFieldKeyValue(i)">
                  <mat-icon>close</mat-icon>
                </button>
              </td>
            </tr>
            </ng-container>
          </table>
        </div>
        <button type="button" mat-stroked-button (click)="addReportFieldKeyValue()">
          {{ 'category.reportFields.add' | translate }}
        </button>

        <br>
        <br>
        <br>
    
        <button type="submit" mat-flat-button color="primary">{{ 'form.save' | translate }}</button>

        <br>
        <br>

      </mat-tab>

      <mat-tab label="{{ 'category.tabs.translations' | translate }}">
        <br>
        <h4>{{ 'category.reportTitle' | translate }}</h4>

        <div [formGroup]="keyValueForm">
          <table formArrayName="reportTitles">
            <ng-container *ngFor="let language of reportTitles.controls; let i = index">
            <tr [formGroupName]="i">
              <td class="label">
                <mat-form-field>
                  <mat-label>{{ 'category.translation.code' | translate }}</mat-label>
                  <input matInput formControlName="code">
                </mat-form-field>
              </td>
              <td class="separator">:</td>
              <td class="value">
                <mat-form-field>
                  <mat-label>{{ 'category.translation.value' | translate }}</mat-label>
                  <input matInput formControlName="value">
                </mat-form-field>
              </td>
              <td>
                <button mat-icon-button (click)="removeReportTitleTranslation(i)">
                  <mat-icon>close</mat-icon>
                </button>
              </td>
            </tr>
            </ng-container>
          </table>
        </div>
        <button type="button" mat-stroked-button (click)="addReportTitleTranslation()">
          {{ 'category.translation.add' | translate }}
        </button>
    
        <br>
        <br>
        <br>
    
        <h4>{{ 'category.reportFilename' | translate }}</h4>
    
        <div [formGroup]="keyValueForm">
          <table formArrayName="reportFilenames">
            <ng-container *ngFor="let reportFilename of reportFilenames.controls; let i = index">
            <tr [formGroupName]="i">
              <td class="label">
                <mat-form-field>
                  <mat-label>{{ 'category.translation.code' | translate }}</mat-label>
                  <input matInput formControlName="code">
                </mat-form-field>
              </td>
              <td class="separator">:</td>
              <td class="value">
                <mat-form-field>
                  <mat-label>{{ 'category.translation.value' | translate }}</mat-label>
                  <input matInput formControlName="value">
                </mat-form-field>
              </td>
              <td>
                <button mat-icon-button (click)="removeReportFilenameTranslation(i)">
                  <mat-icon>close</mat-icon>
                </button>
              </td>
            </tr>
            </ng-container>
          </table>
        </div>
        <button type="button" mat-stroked-button (click)="addReportFilenameTranslation()">
          {{ 'category.translation.add' | translate }}
        </button>

        <br>
        <br>
        <br>
    
        <button type="submit" mat-flat-button color="primary">{{ 'form.save' | translate }}</button>

        <br>
        <br>

      </mat-tab>

      <mat-tab label="{{ 'category.tabs.webServices' | translate }}">
        <br>
        <app-webservices-list 
          *ngIf="model.id && !loading"
          [categoryId]="model.id" 
          [webServiceType]="GET_PROJECT" 
          [webServices]="getWebServices(GET_PROJECT)"
          (onChange)="updateWebServices()">
        </app-webservices-list>
    
        <app-webservices-list 
          *ngIf="model.id && !loading"
          [categoryId]="model.id" 
          [webServiceType]="POST_PROJECT" 
          [webServices]="getWebServices(POST_PROJECT)"
          (onChange)="updateWebServices()">
        </app-webservices-list>
    
        <app-webservices-list 
          *ngIf="model.id && !loading"
          [categoryId]="model.id" 
          [webServiceType]="POST_REPORT" 
          [webServices]="getWebServices(POST_REPORT)"
          (onChange)="updateWebServices()">
        </app-webservices-list>
      </mat-tab>

    </mat-tab-group>
  </form>
</div>
