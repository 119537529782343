import { Component } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { Severity, UserMessageDto } from 'src/app/lib/api/api.client';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ReportService } from 'src/app/lib/service/report.service';

@Component({
  selector: 'app-test-plants-form',
  standalone: true,
  imports: [
    CommonModule, 
    TranslateModule,
    ReactiveFormsModule, 
    FormlyModule, 
    MatButtonModule
  ],
  templateUrl: './user-messages-form.component.html',
  styleUrls: ['./user-messages-form.component.scss'],
})
export class UserMessagesFormComponent {
  form = new FormGroup({});
  model = {} as any;
  fields: FormlyFieldConfig[] = [
    {
      key: 'text',
      type: 'input',
      props: {
        label: this.translateService.instant('userMessage.text'),
        required: true,
      },
    },
    {
      key: 'severity',
      type: 'select',
      defaultValue: Severity._0,
      props: {
        label: this.translateService.instant('userMessage.severity'),
        required: true,
        options: [
          { value: Severity._0, label: this.translateService.instant('severity.0') },
          { value: Severity._1, label: this.translateService.instant('severity.1')  },
          { value: Severity._2, label: this.translateService.instant('severity.2')  },
          { value: Severity._3, label: this.translateService.instant('severity.3')  },
        ],
      },
    },
  ];

  constructor(
    private router: Router, 
    private location: Location, 
    private translateService: TranslateService,
    private reportService: ReportService
  ) {}

  ngOnInit(): void {
    this.model = this.location.getState() as UserMessageDto;
  }

  onSubmit() {
    var state = this.location.getState() as UserMessageDto;
    var userMessages = this.reportService.userMessages$.getValue();
    if (state) {
      userMessages = userMessages.filter((userMessage) => userMessage.text !== state.text && userMessage.severity !== state.severity); 
    }

    userMessages.push({ text: this.form.get('text')?.value ?? '', severity: this.form.get('severity')?.value ?? 0 });
    this.reportService.userMessages$.next(userMessages)
    this.router.navigate(["/testing"]);
  }
}
