import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChooseProjectResult, ProjectClient, ProjectDto } from 'src/app/lib/api/api.client';
import { Observable, map, of } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CorrectionValuesComponent } from '../correction-values/correction-values.component';
import { NOT_EXECUTABLE_ON_TEST_PLANT } from 'src/app/lib/constants';

@Component({
  selector: 'app-project-select',
  standalone: true,
  imports: [
    CommonModule, 
    TranslateModule,
    MatButtonModule,
    MatCheckboxModule,
    CorrectionValuesComponent,
  ],
  providers: [ProjectClient],
  templateUrl: './project-select.component.html',
  styleUrls: ['./project-select.component.scss']
})
export class ProjectSelectComponent {
  @Output() onProjectResult = new EventEmitter<ChooseProjectResult>();

  currentProject: ProjectDto | null = null;
  
  projects$: Observable<Array<ProjectDto>> = this.projectClient.getProjects();

  chooseProjectResult: ChooseProjectResult | undefined;

  NOT_EXECUTABLE = NOT_EXECUTABLE_ON_TEST_PLANT;

  constructor (
    private projectClient: ProjectClient
  ) {}

  setProject(project: ProjectDto) {
    this.currentProject = project;
    this.projectClient.chooseProject(project)
      .subscribe((result: ChooseProjectResult) => {
        this.onProjectResult.emit(result);
        this.chooseProjectResult = result;
      });
  }

  onSubmit(): Observable<void> {
    if (this.chooseProjectResult?.correctionValues?.length) {
      return this.projectClient.setCorrectionValues(this.chooseProjectResult.correctionValues)
      .pipe(map(() => {
        return;
      }));
    } else {
      return of(void 0);
    }
  }
}
