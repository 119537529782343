import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from 'src/app/lib/components/table/table.component';
import { Library } from './library.model';
import { BehaviorSubject, Observable, Subject, map, of, switchMap } from 'rxjs';
import { DeserializeArray, JsonArray } from 'cerializr';
import { MatButtonModule } from '@angular/material/button';
import { Router, RouterModule } from '@angular/router';
import { CompanyClient, LibraryDto } from 'src/app/lib/api/api.client';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { DeleteConfirmComponent } from 'src/app/atom/delete-confirm/delete-confirm.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-libraries-list',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    TableComponent,
    MatButtonModule,
    RouterModule,
    MatDialogModule,
  ],
  templateUrl: './libraries-list.component.html',
  styleUrls: ['./libraries-list.component.scss'],
})
export class LibrariesListComponent {
  private notification$: Subject<null> = new BehaviorSubject<null>(null);

  private originalLibraries: LibraryDto[];

  libraries$: Observable<Library[]> = this.companyClient
    .getLibraries()
    .pipe(map((res) => {
      this.originalLibraries = res;
      return DeserializeArray(res as JsonArray, Library)
  }));

  data$ = this.notification$.pipe(switchMap(() => this.libraries$));

  constructor(
    private router: Router,
    private companyClient: CompanyClient,
    public dialog: MatDialog
  ) {}

  onDelete(entry: Library) {
    const dialogRef = this.dialog.open(DeleteConfirmComponent);
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.companyClient.deleteLibrary(entry).subscribe(() => {
          this.notification$.next(null);
        });
      }
    });
  }

  onEdit(entry: Library) {
    const item = this.originalLibraries.find((item: LibraryDto) => item.id === entry.id);
    this.router.navigate(['/libraries/edit'], { state: entry });
  }
}
