import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TestingFlowComponent } from './testing-flow.component';
import { UserMessagesFormComponent } from 'src/app/organisms/crud/user-messages-form/user-messages-form.component';

const routes: Routes = [
  {
    path: '',
    component: TestingFlowComponent,
    data: { shouldReuseRoute: true }
  },
  {
    path: 'add',
    component: UserMessagesFormComponent
  },
  {
    path: 'edit',
    component: UserMessagesFormComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TestingFlowRoutingModule { }
