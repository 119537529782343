import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProjectsPageComponent } from './projects-page.component';
import { ProjectsFormComponent } from 'src/app/organisms/crud/projects-form/projects-form.component';
import { ProjectsListComponent } from 'src/app/organisms/crud/projects-list/projects-list.component';

const routes: Routes = [
  {
    path: '',
    component: ProjectsPageComponent,
    children: [
      {
        path: '',
        component: ProjectsListComponent
      },
      {
        path: 'add',
        component: ProjectsFormComponent
      },
      {
        path: 'edit',
        component: ProjectsFormComponent
      }
    ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProjectsPageRoutingModule { }
