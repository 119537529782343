import { Component, EventEmitter, Output, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { TextInputDto } from 'src/app/lib/api/api.client';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-text-input',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
  ],
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
})
export class TextInputComponent {
  @Output() submitClicked = new EventEmitter<any>();

  form = this.formBuilder.group({
    text: new FormControl(null)
  });

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<TextInputComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TextInputDto
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.submitClicked.emit(this.form.value);
      this.dialogRef.close();
    }
  }
}

