<h1 mat-dialog-title>
  {{ 'import.table.title' | translate }}
  <button class="close" mat-mini-fab color="primary" (click)="onDismiss()">
    <mat-icon>close</mat-icon>
  </button>
</h1>
<div mat-dialog-content>
  <form [formGroup]="form">
    <mat-form-field>
      <ngx-mat-file-input
        #removableInput formControlName="fileEvent"
        valuePlaceholder="{{ 'import.table.file' | translate }}" 
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
      </ngx-mat-file-input>
      <button mat-icon-button matSuffix *ngIf="!removableInput.empty" (click)="removableInput.clear($event)">
        <mat-icon class="remove">delete_forever</mat-icon>
      </button>
    </mat-form-field>
    <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
  </form>
</div>
<div mat-dialog-actions>
  <div>&nbsp;</div>
  <button mat-flat-button color="primary" 
    mat-dialog-close cdkFocusInitial 
    [disabled]="form.invalid || !form.get('fileEvent')?.value" (click)="onConfirm()">
    {{ 'import.confirm' | translate }}
  </button>
</div>