import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReportsPageComponent } from './reports-page.component';
import { ReportsListComponent } from 'src/app/organisms/crud/reports-list/reports-list.component';
import { ReportsFormComponent } from 'src/app/organisms/crud/reports-form/reports-form.component';

const routes: Routes = [{
  path: '',
  component: ReportsPageComponent,
  children: [
    {
      path: '',
      component: ReportsListComponent
    },
    {
      path: 'add',
      component: ReportsFormComponent
    },
    {
      path: 'edit',
      component: ReportsFormComponent
    }
  ]
},];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsPageRoutingModule { }
