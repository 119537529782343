import { Injectable } from '@angular/core';
import { CategoryType, UserClient } from '../api/api.client';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { SettingsStorageService } from './settings-storage.service';
import { AuthStorageService } from './auth-storage.service';
import { BehaviorSubject } from 'rxjs';
import { CATEGORY_NONE } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public name$ = new BehaviorSubject(this.authStorageService.getName());
  public role$ = new BehaviorSubject(this.authStorageService.getRole());
  public runningCategoryType$ = new BehaviorSubject<CategoryType>(CATEGORY_NONE);

  constructor(
    private userClient: UserClient,
    private dateAdapter: DateAdapter<any>,
    private translateService: TranslateService,
    private settingsStorageService: SettingsStorageService,
    private authStorageService: AuthStorageService
  ) {}

  setLanguage(language: string) {
    this.settingsStorageService.setLanguage(language);
    this.translateService.use(language);
    this.dateAdapter.setLocale(language);

    if (this.authStorageService.isLoggedIn()) {
      this.userClient.setCultureInfo(language).subscribe();
    }
  }

  setZoomFactor(zoomFactor: string) {
    this.settingsStorageService.setZoomFactor(zoomFactor);
  }

  setRole(role: string) {
    this.role$.next(role);
    this.authStorageService.setRole(role);
  }

  setName(name: string) {
    this.name$.next(name);
    this.authStorageService.setName(name);
  }

  setRunningCategoryType(categoryType: CategoryType) {
    this.runningCategoryType$.next(categoryType);
  }

}
