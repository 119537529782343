<table mat-table [dataSource]="data" matSort (matSortChange)="sortData($event)">
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button
        type="button"
        mat-icon-button
        [matMenuTriggerFor]="menu"
        (click)="$event.stopPropagation()"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button (click)="editClicked(element)" mat-menu-item>
          <mat-icon>edit</mat-icon>
          <span>{{ 'table.edit' | translate }}</span>
        </button>
        <button (click)="deleteClicked(element)" mat-menu-item>
          <mat-icon>delete</mat-icon>
          <span>{{ 'table.delete' | translate }}</span>
        </button>
      </mat-menu>
    </td>
  </ng-container>
  <ng-container [matColumnDef]="column.key" *ngFor="let column of columns">
    <ng-container *ngIf="column.canSort; else noSort">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="{{ column.key }}">
        {{ this.entity + '.' + column.key | translate }}
      </th>
    </ng-container>
    <ng-template #noSort>
      <th mat-header-cell *matHeaderCellDef>{{ this.entity + '.' + column.key | translate }}</th>
    </ng-template>
    <td mat-cell *matCellDef="let element">
      <ng-container *ngIf="!column.translatePath">{{ element[column.key] }}</ng-container>
      <ng-container *ngIf="column.translatePath">{{ column.translatePath + '.' + element[column.key] | translate }}</ng-container>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="editClicked(row)" class="pointer"></tr>
</table>
