import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AuthService } from '../lib/service/auth.service';
import { UserService } from '../lib/service/user.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSidenav } from '@angular/material/sidenav';
import { map, tap } from 'rxjs';
import { CATEGORY_NONE, CHECKLIST_TYPE, HARDWARE_TYPE } from '../lib/constants';
import { CategoryDto, CategoryType, FavoriteDto, ProgressStepClient, ProgressStepDto } from '../lib/api/api.client';
import { Router } from '@angular/router';
import { StateService } from '../lib/service/state.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '../lib/service/data.service';
import { SettingsStorageService } from '../lib/service/settings-storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  readonly ADMIN = 'Administrator';

  loggedIn = false;

  sideNavOnlyIcons = false;

  loggedIn$ = this.authService.loggedIn$.pipe(map((loggedIn: boolean) => {
    this.loggedIn = loggedIn;

    if (loggedIn) {
      setTimeout(() => {
        this.progressClient.getCurrentProgressStep().subscribe((progressStep: ProgressStepDto) => {
          if (progressStep.categoryType) {
            this.userService.setRunningCategoryType(progressStep.categoryType);
          }
        });
      });
    }

    return loggedIn;
  }));
  name$ = this.userService.name$;
  role$ = this.userService.role$;

  zoomFactor$ = this.settingsStorageService.zoomFactor$.pipe(tap((zoomFactor: number) => {
    (document.body.style as any).zoom = `${zoomFactor}%`;
    this.cd.detectChanges();
  }));

  linksAdmin = [
    { label: 'navbar.users', link: '/users', icon: 'users' },
    { label: 'navbar.projects', link: '/projects', icon: 'projects' },
    { label: 'navbar.libraries', link: '/libraries', icon: 'libraries' },
    { label: 'navbar.reports', link: '/reports', icon: 'reports' },
    { label: 'navbar.categories', link: '/categories', icon: 'categories' },
    { label: 'navbar.favorites', link: '/favorites', icon: 'favorites' },
  ];

  readonly sidenavBreakpoint = 1000;
  screenWidth = 0;

  CATEGORY_NONE = CATEGORY_NONE;
  HARDWARE_TYPE = HARDWARE_TYPE;
  CHECKLIST_TYPE = CHECKLIST_TYPE;

  runningCategoryType: CategoryType = CATEGORY_NONE;

  runningCategoryType$ = this.userService.runningCategoryType$.pipe(map((type: CategoryType) => {
    this.runningCategoryType = type;
    return type;
  }));

  hasHardware: boolean;
  hasChecklist: boolean;

  favorites$ = this.stateService.favorites$;

  categories$ = this.stateService.categories$.pipe(map((categories: CategoryDto[]) => {
    this.initSupportedCategoryTypes(categories);
    return categories;
  }));

  @ViewChild('snav') sidenav: MatSidenav;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
      if (this.loggedIn) {
        this.screenWidth = event.target.innerWidth;
        if (event.target.innerWidth < this.sidenavBreakpoint) {
          if (!this.sideNavOnlyIcons) {
            this.toggleSidenav();
          }
        } else {
          if (this.sideNavOnlyIcons) {
            this.toggleSidenav();
          }
        }
      }
  }

  constructor(
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private userService: UserService,
    private settingsStorageService: SettingsStorageService,
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    private progressClient: ProgressStepClient,
    private dataService: DataService,
    private stateService: StateService,
    private translateService: TranslateService,
    private snack: MatSnackBar,
    private cd: ChangeDetectorRef
  ) {
    this.matIconRegistry.addSvgIcon("pdf", this.sanitizer.bypassSecurityTrustResourceUrl("../assets/svg/filetype-pdf.svg"));
    this.matIconRegistry.addSvgIcon("xml", this.sanitizer.bypassSecurityTrustResourceUrl("../assets/svg/filetype-xml.svg"));
    this.matIconRegistry.addSvgIcon("xlsx", this.sanitizer.bypassSecurityTrustResourceUrl("../assets/svg/filetype-xlsx.svg"));
    this.matIconRegistry.addSvgIcon("docx", this.sanitizer.bypassSecurityTrustResourceUrl("../assets/svg/filetype-docx.svg"));
    this.matIconRegistry.addSvgIcon("images", this.sanitizer.bypassSecurityTrustResourceUrl("../assets/svg/images.svg"));

    this.matIconRegistry.addSvgIcon("profile", this.sanitizer.bypassSecurityTrustResourceUrl("../assets/svg/profile.svg"));
    this.matIconRegistry.addSvgIcon("testing", this.sanitizer.bypassSecurityTrustResourceUrl("../assets/svg/testing.svg"));
    this.matIconRegistry.addSvgIcon("users", this.sanitizer.bypassSecurityTrustResourceUrl("../assets/svg/users.svg"));
    this.matIconRegistry.addSvgIcon("projects", this.sanitizer.bypassSecurityTrustResourceUrl("../assets/svg/projects.svg"));
    this.matIconRegistry.addSvgIcon("libraries", this.sanitizer.bypassSecurityTrustResourceUrl("../assets/svg/libraries.svg"));
    this.matIconRegistry.addSvgIcon("reports", this.sanitizer.bypassSecurityTrustResourceUrl("../assets/svg/reports.svg"));
    this.matIconRegistry.addSvgIcon("hardware", this.sanitizer.bypassSecurityTrustResourceUrl("../assets/svg/hardware.svg"));
    this.matIconRegistry.addSvgIcon("checklist", this.sanitizer.bypassSecurityTrustResourceUrl("../assets/svg/checklist.svg"));
    this.matIconRegistry.addSvgIcon("categories", this.sanitizer.bypassSecurityTrustResourceUrl("../assets/svg/categories.svg"));
    this.matIconRegistry.addSvgIcon("favorites", this.sanitizer.bypassSecurityTrustResourceUrl("../assets/svg/hardware.svg"));
  }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
  }

  ngAfterViewInit(): void {
    if (this.screenWidth < this.sidenavBreakpoint && this.loggedIn) {
      setTimeout(() => {
        this.toggleSidenav();
      }, 100);
    }

    if (this.loggedIn) {
      this.dataService.getInitialData();
    }

    this.initSupportedCategoryTypes(this.stateService.categoriesSubject.getValue());
  }

  private initSupportedCategoryTypes(categories: CategoryDto[]) {
    if (categories.length) {
      this.hasHardware = false;
      this.hasChecklist = false;
      categories.forEach((category: CategoryDto) => {
        switch (category.type) {
          case HARDWARE_TYPE:
            this.hasHardware = true;
            break;
          case CHECKLIST_TYPE:
            this.hasChecklist = true;
            break;
        }
      });
    }
  }

  openFavorite(favorite: FavoriteDto) {
    if (this.runningCategoryType !== this.CATEGORY_NONE) {
      this.snack.open(this.translateService.instant('navbar.stopHint'));
    } else {
      this.openFavoriteLink(favorite);
    }
  }

  private openFavoriteLink(favorite: FavoriteDto) {
    let link = '';
    switch (favorite.category?.type) {
      case HARDWARE_TYPE:
        link = '/testing';
        break;
      case CHECKLIST_TYPE:
        link = '/checklist';
        break;
    }
    this.router.navigate([link], { state: favorite });
  }

  openTestingFlow() {
    this.sidenav?.close();
  }

  toggleSidenav() {
    this.animateSidenav();
  }

  onLogout(): void {
    this.loggedIn = false;
    this.authService.logout();
    this.animateSidenav();
  }

  animateSidenav() {
    const isSideNavOpened = this.sidenav?.opened;
    const $matSidenav = this.elementRef.nativeElement.querySelector(
      'mat-sidenav'
    );
    const $matSidenavContent = this.elementRef.nativeElement.querySelector(
      'mat-sidenav-content'
    );

    if (isSideNavOpened && this.loggedIn) {
      this.sidenav?.toggle();
      this.sideNavOnlyIcons = true;
      window.setTimeout(() => {
        this.renderer.setStyle($matSidenav, 'width', '0px');
        this.renderer.setStyle(
          $matSidenav,
          'transform',
          'translate3d(0, 0, 0)'
        );
        window.setTimeout(() => {
          this.renderer.setStyle($matSidenav, 'width', '80px');
          this.renderer.setStyle($matSidenav, 'transition', 'width 0.3s');
          this.renderer.setStyle($matSidenav, 'visibility', 'visible');
          this.renderer.setStyle($matSidenavContent, 'margin-left', '80px');
        }, 100);
      }, 400);
    } else {
      this.renderer.removeStyle($matSidenav, 'width');
      this.renderer.removeStyle($matSidenav, 'transition');
      this.renderer.removeStyle($matSidenav, 'visibility');
      this.renderer.removeStyle($matSidenav, 'transform');
      this.renderer.removeStyle($matSidenavContent, 'margin-left');
      this.sidenav?.toggle();
      this.sideNavOnlyIcons = false;
    }
  }
}
