import { Injectable } from '@angular/core';
import { DataType, UserMessageDto } from '../api/api.client';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReportService {

  userMessages$ = new BehaviorSubject([] as UserMessageDto[]);

  constructor() {}

  getFieldType(dataType: DataType | undefined) {
    switch (dataType) {
      case 0:
        return 'input';
      case 1:
      case 2:
        return 'datepicker';
      default:
        return 'input';
    }
  }

}
