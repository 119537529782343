import { Component } from '@angular/core';
import { UserService } from 'src/app/lib/service/user.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent {
  name$ = this.userService.name$;

  constructor(private userService: UserService) {}
}
