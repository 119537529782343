import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { VariableDto } from 'src/app/lib/api/api.client';

@Component({
  selector: 'app-edit-variable',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule
  ],
  templateUrl: './edit-variable.component.html',
  styleUrls: ['./edit-variable.component.scss'],
})
export class EditVariableComponent {
  @Output() submitClicked = new EventEmitter<any>();
  
  description!: string;
  form = this.formBuilder.group({
    value: new FormControl(this.data.value),
    name: new FormControl(this.data.name)
  });
  
  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<EditVariableComponent>,
    @Inject(MAT_DIALOG_DATA) public data: VariableDto
  ) {}

  close(): void {
    this.dialogRef.close();
  }
  onSubmit(): void {
    this.submitClicked.emit(this.form.value);
    this.dialogRef.close();
  }
}
