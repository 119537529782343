<ng-container *ngIf="slideshow">
  <div class="image-gallery-container" *ngIf="slideshow && slideshow.length > 0">
    <slideshow
      [height]="'500px'"
      [autoPlay]="false"
      [showArrows]="true"
      [showDots]="false"
      [showCaptions]="false"
      [disableSwiping]="true"
      [backgroundSize]="'contain'"
      [imageUrls]="slideshow"
      [noLoop]="false"
    ></slideshow>
  </div>
</ng-container>
