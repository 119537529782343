import { Component } from '@angular/core';

@Component({
  selector: 'app-webservices-page',
  templateUrl: './webservices-page.component.html',
  styleUrls: ['./webservices-page.component.scss']
})
export class WebServicesPageComponent {

}
