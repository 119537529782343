import { Injectable } from '@angular/core';

import { CategoryDto, CompanyClient, FavoriteDto } from '../api/api.client';
import { StateService } from './state.service';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    private companyClient: CompanyClient,
    private stateService: StateService,
    ) {}

  getInitialData() {
    this.companyClient.getCategories().pipe(map((categories: CategoryDto[]) => {
        this.stateService.updateCategories(categories);
      })).subscribe();

      this.companyClient.getFavoritesByCompanyId().pipe(map((favorites: FavoriteDto[]) => {
        this.stateService.updateFavorites(favorites);
      })).subscribe();
  }
}