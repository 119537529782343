<div class="card">
  <h3 *ngIf="!model.id">{{ 'form.add' | translate }}</h3>
  <h3 *ngIf="model.id">{{ 'form.edit' | translate }}</h3>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
    <app-file-input allowedFileExtension=".unipas" (newFileDataEvent)="updateFileData($event)"></app-file-input>
    <ng-container *ngIf="missingLibrariesError">
      <h2>{{ 'project.missingLibraries' | translate }}</h2>
      <h2 class="warn">{{ missingLibrariesError }}</h2>
    </ng-container>
    <br>

    <ng-container *ngIf="model.category?.webServices?.length">
      <div *ngFor="let webService of model.category?.webServices">
        <button type="button" mat-stroked-button
          (click)="checkIn(webService)">{{ webService.buttonTitle }}</button>
      </div>
    </ng-container>

    <br>
    <br>
    <br>

    <button type="submit" mat-flat-button color="primary">{{ 'form.save' | translate }}</button>
  </form>
</div>
