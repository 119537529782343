<div [ngClass]="{'header-hidden': !viewState.category && isHardwareOnly === false && isChecklistOnly === false}">
  <div>
    <mat-horizontal-stepper #stepper>

      <mat-step [completed]="isStepCompleted(step.CATEGORY_SELECT)">
        <ng-template matStepLabel>
          {{ "steps.1" | translate }}
          <br><small *ngIf="isStepCompleted(step.CATEGORY_SELECT) && viewState?.category?.name">{{viewState.category.name}}</small>
        </ng-template>
        <div class="main-block" *ngIf="progressStep.currentProgressStep == getStepIndex(step.CATEGORY_SELECT)">
          <app-category-select *ngIf="progressStep.currentProgressStep == getStepIndex(step.CATEGORY_SELECT)" 
            [categoryId]="categoryId"
            [categoryType]="categoryType"
            (onCategoriesReceived)="onCategoriesReceived($event)" (onCategorySelect)="onCategorySelect($event)"></app-category-select>
        </div>
      </mat-step>
      <mat-step [completed]="isStepCompleted(step.HARDWARE_SELECT)" *ngIf="viewState.category?.type == HARDWARE || isHardwareOnly === true">
        <ng-template matStepLabel>
          {{ "steps.2" | translate }}
          <br><small *ngIf="isStepCompleted(step.HARDWARE_SELECT) && viewState?.testPlant?.name">{{viewState.testPlant.name}}</small>
        </ng-template>
        <div class="main-block" *ngIf="progressStep.currentProgressStep == getStepIndex(step.HARDWARE_SELECT)">
          <app-test-plant-select *ngIf="progressStep.currentProgressStep == getStepIndex(step.HARDWARE_SELECT)"
            [testPlantId]="testPlantId" 
            (onTestPlantSelect)="onTestPlantSelect($event)"></app-test-plant-select>
        </div>
      </mat-step>
      <mat-step [completed]="isStepCompleted(step.PROJECT_SELECT)">
        <ng-template matStepLabel>
          {{ "steps.3" | translate }}
          <br><small *ngIf="isStepCompleted(step.PROJECT_SELECT) && viewState?.projectResult?.name">{{viewState.projectResult.name}}</small>
        </ng-template>
        <div class="main-block" *ngIf="progressStep.currentProgressStep == getStepIndex(step.PROJECT_SELECT)">
          <app-project-select 
            *ngIf="progressStep.currentProgressStep == getStepIndex(step.PROJECT_SELECT) && (!viewState.category?.chooseProjectWebService || viewState.category?.chooseProjectWebService == GUID_EMPTY)"
            (onProjectResult)="onProjectResult($event)">
          </app-project-select>
          <app-webservice-select 
            *ngIf="progressStep.currentProgressStep == getStepIndex(step.PROJECT_SELECT) && (viewState.category?.chooseProjectWebService && viewState.category?.chooseProjectWebService != GUID_EMPTY)" 
            (onProjectResult)="onProjectResult($event)"
            [webServices]="getWebServices(GET_PROJECT)"
            [category]="viewState.category">
          </app-webservice-select>
        </div>
      </mat-step>
      <mat-step [completed]="isStepCompleted(step.REPORT_SELECT)">
        <ng-template matStepLabel>
          {{ "steps.4" | translate }}
          <br><small *ngIf="isStepCompleted(step.REPORT_SELECT) && viewState?.report?.name">{{viewState.report.name}}</small>
        </ng-template>
        <div class="main-block" *ngIf="progressStep.currentProgressStep == getStepIndex(step.REPORT_SELECT)">
          <app-report-select 
            *ngIf="progressStep.currentProgressStep == getStepIndex(step.REPORT_SELECT)" 
            [category]="viewState.category"
            (onReportSelect)="onReportSelect($event)">
          </app-report-select>
        </div>
      </mat-step>
      <mat-step [completed]="isStepCompleted(step.PLAYER)">
        <ng-template matStepLabel>
          <span *ngIf="isHardwareOnly">{{ "navbar.testing" | translate }}</span>
          <span *ngIf="isChecklistOnly">{{ "navbar.checklist" | translate }}</span>
        </ng-template>
        <app-player-page *ngIf="progressStep.currentProgressStep == getStepIndex(step.PLAYER)"
          [category]="viewState.category"></app-player-page>
      </mat-step>
      <mat-step [completed]="isStepCompleted(step.REPORT)">
        <ng-template matStepLabel>{{ "steps.6" | translate }}</ng-template>
        <app-report-page *ngIf="progressStep.currentProgressStep == getStepIndex(step.REPORT)" 
          [category]="viewState.category" [webServices]="getWebServices(POST_REPORT)">
        </app-report-page>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
  <div *ngIf="progressStep$ | async; else disabledButtons">
    <div class="control-button stop-button"
      [ngClass]="{
        'disable': progressStep.stopButtonState == buttonDisabled, 
        'invisible': progressStep.stopButtonState == buttonInvisible
      }"
      (click)="stopClicked()">
      <mat-icon fontIcon="stop_circle"></mat-icon>
    </div>
    <div *ngIf="stepper.selectedIndex+1 !== getStepIndex(step.REPORT_SELECT) && stepper.selectedIndex+1 !== getStepIndex(step.PROJECT_SELECT)"
      class="control-button next-button" 
      [ngClass]="{
        'disable': progressStep.continueButtonState == buttonDisabled, 
        'invisible': progressStep.continueButtonState == buttonInvisible
      }" 
      (click)="nextClicked()">
      <mat-icon fontIcon="arrow_circle_right"></mat-icon>
    </div>
    <div *ngIf="stepper.selectedIndex+1 === getStepIndex(step.PROJECT_SELECT)" 
      class="control-button next-button" [ngClass]="{'disable': progressStep.continueButtonState == buttonDisabled}"
      (click)="submitProject()">
      <mat-icon fontIcon="arrow_circle_right"></mat-icon>
    </div>
    <div *ngIf="stepper.selectedIndex+1 === getStepIndex(step.REPORT_SELECT)" 
      class="control-button next-button" [ngClass]="{'disable': progressStep.continueButtonState == buttonDisabled && !viewState.report}"
      (click)="submitReport()">
      <mat-icon fontIcon="arrow_circle_right"></mat-icon>
    </div>
    <div *ngIf="stepper.selectedIndex+1 === getStepIndex(step.PLAYER) && isPlayButtonEnabled" 
      class="control-button play-button" (click)="startPlayer()">
      <mat-icon fontIcon="play_circle"></mat-icon>
    </div>
  </div>
  <ng-template #disabledButtons>
    <div class="control-button stop-button disable">
      <mat-icon fontIcon="stop_circle"></mat-icon>
    </div>
    <div class="control-button next-button disable">
      <mat-icon fontIcon="arrow_circle_right"></mat-icon>
    </div>
  </ng-template>
</div>
<router-outlet></router-outlet>
