<h1 mat-dialog-title>{{ translation.title | translate }}</h1>
<div mat-dialog-content>{{ translation.content | translate }}</div>
<div mat-dialog-actions>
  <button mat-stroked-button mat-dialog-close (click)="onDismiss()">
    {{ translation.cancel | translate }}
  </button>
  <button mat-flat-button color="primary" mat-dialog-close cdkFocusInitial (click)="onConfirm()">
    {{ translation.confirm | translate }}
  </button>
</div>
