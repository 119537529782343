<ng-container *ngIf="meta$ | async as meta">
  <div class="title-bar">
    <div class="title">{{ meta.projectName }}</div>
    <ng-container *ngIf="category?.type == HARDWARE_TYPE">
      <ng-container *ngIf="appStatus$ | async as appStatus">
        <div class="status">
          <ng-container *ngIf="hardwareStatus$ | async as hardwareStatus">
            {{ 'player.appStatus' | translate }}
            <app-hardware-status [hardwareStatus]="hardwareStatus"></app-hardware-status>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
<ng-container *ngIf="messages$">
  <div #scrollContainer class="player-main-container">
    <ng-container *ngIf="(messages$ | async) as messages">
      <app-message
        *ngFor="let message of messages; index as i"
        [message]="message"
        [isLastMessage]="messages.length == i+1"
      ></app-message>
    </ng-container>
    <app-image-gallery [images$]="images$"></app-image-gallery>
  </div>
</ng-container>

