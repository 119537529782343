import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { AppStateDto, HardwareInterfaceStateDto, FileDto, MessageDto, PlayerDto, CategoryDto } from 'src/app/lib/api/api.client';
import { HARDWARE_TYPE } from 'src/app/lib/constants';

@Component({
  selector: 'app-player-main',
  templateUrl: './player-main.component.html',
  styleUrls: ['./player-main.component.scss']
})
export class PlayerMainComponent implements OnInit {
  @Input() category: CategoryDto | undefined;
  @Input() meta$!: Observable<PlayerDto>;
  @Input() messages$: Observable<Array<MessageDto>> | undefined;
  @Input() images$!: Observable<Array<FileDto>>;
  @Input() appStatus$!: Observable<AppStateDto>;
  @Input() hardwareStatus$!: Observable<HardwareInterfaceStateDto[]>

  @ViewChild('scrollContainer') private scrollContainer!: ElementRef;

  HARDWARE_TYPE = HARDWARE_TYPE;

  ngOnInit(): void {
    console.log('init PlayerMainComponent')
  }

  scrollToBottom(): void {
    if (this.scrollContainer?.nativeElement) {
      this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
    }               
  }
}
