import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import {
  ReportTemplateClient,
  HeaderItemDto,
  ReportConfigurationDto,
  CategoryDto,
} from 'src/app/lib/api/api.client';
import { Observable, map, of } from 'rxjs';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { MatButtonModule } from '@angular/material/button';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { Moment } from 'moment';
import * as moment from 'moment';
import { TranslateModule } from '@ngx-translate/core';
import { ReportService } from 'src/app/lib/service/report.service';

@Component({
  selector: 'app-report-select',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormlyModule,
    MatButtonModule,
    FormlyMatDatepickerModule,
  ],
  providers: [ReportTemplateClient],
  templateUrl: './report-select.component.html',
  styleUrls: ['./report-select.component.scss'],
})
export class ReportSelectComponent {
  @Input() category: CategoryDto | undefined;
  @Output() onReportSelect = new EventEmitter<ReportConfigurationDto>();

  currentReport: ReportConfigurationDto | null = null;
  reports$: Observable<Array<ReportConfigurationDto>> =
    this.reportTemplateClient.getReports().pipe(
      map((reports: ReportConfigurationDto[]) => {
        if (reports.length === 1) {
          this.currentReport = reports[0];
          this.onTypeSelection({ value: this.currentReport } as MatSelectChange);
        }
        return reports;
      })
    );

  form = new FormGroup({});
  model = {};
  fields: FormlyFieldConfig[] = [];

  constructor(
    private reportTemplateClient: ReportTemplateClient,
    private reportService: ReportService
  ) {}

  onTypeSelection(event: MatSelectChange) {
    this.currentReport = null;
    this.model = {};
    this.form = new FormGroup({});
    this.form.reset();

    this.fields = event.value.headerItems.map((headerItem: HeaderItemDto) => {
      const states = this.getPredefinedValues(headerItem.name);
      return {
        key: headerItem.name,
        type: states.length ? 'autocomplete' : this.reportService.getFieldType(headerItem.dataType),
        defaultValue: states.length ? undefined : headerItem.value,
        props: {
          label: headerItem.label?.toUpperCase(),
          required: headerItem.isMandatory,
          filter: (term: string) => {
            return of(term ? this.filterStates(term, states) : states)
          },
        }
      };
    });
    this.currentReport = event.value;
    if (this.currentReport) {
      this.onReportSelect.emit(this.currentReport);
    }
  }

  onSubmit(): Observable<void> {
    this.form.markAllAsTouched(); 
    if (this.form.valid && this.currentReport) {
      const apiObject = {
        ...this.currentReport,
        headerItems: this.currentReport?.headerItems?.map((headerItem) => ({
          ...headerItem,
          value: this.parseValue(headerItem)
        }))
      }

      return this.reportTemplateClient.chooseReport(apiObject)
    } else {
      return of(void 0)
    }
  }

  private parseValue(headerItem: HeaderItemDto) {
    const value = this.form.value[headerItem.name as keyof typeof this.form.value];
    if (headerItem.dataType === 1 && moment.isMoment(value)) {
      const momentVal = value as Moment;
      return momentVal.format('YYYY/MM/DD');
    }
    return value;
  }

  private getPredefinedValues(key: string | undefined): string[] {
    let values = [] as string[];
    if (key && this.category?.reportFieldsAsJsonString) {
      Object.entries(JSON.parse(this.category.reportFieldsAsJsonString)).every((keyValue: any) => {
        if (key.toLocaleLowerCase() !== keyValue[0].toLowerCase()) {
          return true;
        } else {
          const separator = keyValue[1]?.indexOf(';') !== -1 ? ';' : ',';
          values = keyValue[1].split(separator)?.filter((value: string) => !!value);
          return false;
        }
      });
    } 
    return values;
  }

  private filterStates(_name: string, states: string[]) {
    return states; // always show all options
    // return states.filter(state => state.toLowerCase().indexOf(_name.toLowerCase()) === 0);
  }
}
