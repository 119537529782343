import { Component, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription, interval, map, mergeMap, scan, tap } from 'rxjs';
import {
  AppStateDto,
  ButtonStateDto,
  ButtonType,
  HardwareInterfaceStateDto,
  FileDto,
  MessageDto,
  PlayerClient,
  PlayerDto,
  VariableDto,
  CategoryDto,
  TextInputDto,
} from 'src/app/lib/api/api.client';
import { VariableStorageService } from 'src/app/lib/service/variable-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { EditVariableComponent } from 'src/app/molecules/edit-variable/edit-variable.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DefineMessageComponent } from 'src/app/molecules/define-message/define-message.component';
import { PlayerMainComponent } from 'src/app/organisms/player-main/player-main.component';
import { DeleteConfirmComponent } from 'src/app/atom/delete-confirm/delete-confirm.component';
import { SignalRService } from 'src/app/lib/service/signalr.service';
import { ENABLED_STATE, SEVERITY_ERROR, SEVERITY_PENDING, SEVERITY_USER_DEFINED, START_BUTTON, VARIABLE_TYPE_HARDWARE_OUTPUT } from 'src/app/lib/constants';
import { TextInputComponent } from 'src/app/molecules/text-input/text-input.component';

@Component({
  selector: 'app-player-page',
  templateUrl: './player-page.component.html',
  styleUrls: ['./player-page.component.scss'],
})
export class PlayerPageComponent implements OnInit, OnDestroy {
  @Input() category: CategoryDto | undefined;

  @ViewChild(PlayerMainComponent) playerMainComponent: PlayerMainComponent;

  messagesSubject = new BehaviorSubject<MessageDto[]>([]);
  messages$ = this.messagesSubject.asObservable().pipe(
    scan((all, elements) => {
      if (!elements) {
        return [];
      }

      const lastMessage = elements.pop();
      const messages = [...all, ...elements];

      if (lastMessage) {
        const filteredPendingMessages = messages.filter((message) => message.severity !== SEVERITY_PENDING);
        filteredPendingMessages.push(lastMessage);
        return filteredPendingMessages;
      } else {
        return messages;
      }
    }, [] as MessageDto[]),
    tap((_messages: MessageDto[]) => {
      setTimeout(() => {
        this.playerMainComponent.scrollToBottom();
      });
    })
  );

  variables$: Observable<VariableDto[]> | undefined;

  panelOpen = false;
  buttonsLocked = false;

  buttonsSubject = new BehaviorSubject<ButtonStateDto[]>([]);
  buttons$ = this.buttonsSubject.asObservable().pipe(
    mergeMap(() => this.playerClient.getButtonStates()
      .pipe(map((dto: ButtonStateDto[]) => {
        const playButton = dto.find((button: ButtonStateDto) => button.buttonType === START_BUTTON);
        this.isPlayButtonEnabled = playButton?.buttonState === ENABLED_STATE;

        if (this.buttonsLocked) {
          this.buttonsLocked = false;
        }

        return dto;
      }))
    )
  );

  appStatus$: Observable<AppStateDto> = interval(2 * 1000).pipe(
    mergeMap(() => this.playerClient.getAppStates()
      .pipe(map((appState: AppStateDto) => {
        if (appState.isRunning && !this.variables$) {
          this.initVariablesInterval();
        }
        return appState;
      }))
    )
  );
  hardwareStatus$: Observable<HardwareInterfaceStateDto[]> = interval(2 * 1000).pipe(
    mergeMap(() => this.playerClient.getHardwareStates())
  );
  storageVariables$: Observable<VariableDto[]> =
    this.variableStorageService.variables$;

  meta$: Observable<PlayerDto> = this.playerClient.getPlayerDto();

  readonly images$: Subject<Array<FileDto>> = new Subject();

  isPlayButtonEnabled = false;
  currentMessage: MessageDto;

  private readonly subscriptions = new Subscription();

  constructor(
    private dialog: MatDialog,
    private snack: MatSnackBar,
    private playerClient: PlayerClient,
    private variableStorageService: VariableStorageService,
    private signalRService: SignalRService
  ) {}

  ngOnInit():void {
    this.getAllMessages();

    this.subscriptions.add(
      this.signalRService.messagesChanged$.asObservable().subscribe(() => {
        this.getMessages();
        this.getButtons();
      })
    );

    this.subscriptions.add(
      this.signalRService.progressStepChanged$.asObservable().subscribe(() => {
        this.getButtons();
      })
    );

    this.subscriptions.add(
      this.signalRService.textInputRequest$.asObservable().subscribe((data: TextInputDto) => {
        this.textInputDialog(data);
      })
    );
  }

  ngOnDestroy(): void {
    this.images$.complete();
    this.messagesSubject.complete();
    this.buttonsSubject.complete();
    this.subscriptions.unsubscribe();
  }

  private processMessages(messages: MessageDto[]): void {
    if (messages?.length > 0) {
      messages.forEach((message) => {
        //console.log('new message', message.text);
        //console.log('', message.severity, message.messageFormat, message);
      });
      this.currentMessage = messages[messages.length - 1];
      const programImages: FileDto[] = this.currentMessage.programImages as FileDto[];

      if (programImages && programImages.length > 0) {
        this.announceImages(programImages);
      } else {
        this.announceImages([]);
      }

      if (this.currentMessage.severity === SEVERITY_USER_DEFINED 
        && !this.currentMessage.enableImageImport 
        && !this.currentMessage.enableTableImport
        && !this.currentMessage.enableWordImport
      ) {
        this.defineMessage(this.currentMessage);
      }
    }
  }

  defineMessage(message: MessageDto): void {
    const dialogRef = this.dialog.open(DefineMessageComponent, {
      data: message,
      disableClose: true
    });

    const dialogSubmitSubscription = dialogRef.componentInstance.submitClicked.subscribe((result) => {
        if (!result) {
          this.playerClient.stop().subscribe();
        } else {
          this.playerClient.confirmWithMessageResult(result.messageResult).subscribe();
        }
        dialogSubmitSubscription.unsubscribe();
      });
  }

  announceImages(images: Array<FileDto>): void {
    this.images$.next(images);
  }

  addFavouriteVariable(variable: VariableDto) {
    const storageVariables = this.variableStorageService.getVariables();
    if (
      !storageVariables.find(
        (storageVariable) => storageVariable.name === variable.name
      )
    ) {
      this.variableStorageService.setVariables([...storageVariables, variable]);
    }
  }

  removeFavouriteVariable(variable: VariableDto) {
    this.variableStorageService.setVariables(
      this.variableStorageService
        .getVariables()
        .filter((storedVar) => storedVar.name !== variable.name)
    );
  }


  editVariable(variable: VariableDto) {
    if (variable.variableType !== VARIABLE_TYPE_HARDWARE_OUTPUT) {
      // do nothing if not HardwareOutput
      return;
    }

    const dialogRef = this.dialog.open(EditVariableComponent, {
      data: variable,
    });

    const dialogSubmitSubscription =
      dialogRef.componentInstance.submitClicked.subscribe((result) => {
        this.playerClient
          .updateVariableValue(variable.guid, result.value)
          .subscribe(() => {
            this.snack.open(`${result.name} value changed to ${result.value}`);
          });
        dialogSubmitSubscription.unsubscribe();
      });
  }

  onPlayerAction(actionType: ButtonType) {
    if (this.buttonsLocked) {
      return;
    }
    this.buttonsLocked = true;
    setTimeout(() => {
      this.buttonsLocked = false;
    }, 500);

    switch (actionType) {
      case 0:
        this.playerClient.start().subscribe(() => {
          this.getButtons();
          this.initVariablesInterval();
        });
        break;
      case 1:
        this.playerClient.stop().subscribe();
        break;
      case 2:
        this.playerClient.repeat().subscribe();
        break;
      case 3:
        this.playerClient.skip().subscribe();
        break;
      case 4:
        if (this.currentMessage?.severity === SEVERITY_ERROR && this.category?.claimConfirmError) {
          this.confirmError();
        } else {
          this.playerClient.confirm().subscribe();
        }
        break;
      default:
        return;
    }
  }

  private getAllMessages():void {
    this.playerClient.getAllMessages()
          .pipe(
            tap((result) => this.processMessages(result)),
            tap((messages: MessageDto[]) => {
              this.messagesSubject.next(messages);
            })
          ).subscribe();
  }

  private getMessages():void {
    this.playerClient.getMessages()
          .pipe(
            tap((result) => this.processMessages(result)),
            tap((messages: MessageDto[]) => {
              this.messagesSubject.next(messages);
            })
          ).subscribe();
  }

  private getButtons():void {
    this.playerClient.getButtonStates()
          .pipe(
            tap((buttonStates: ButtonStateDto[]) => {
              this.buttonsSubject.next(buttonStates);
            })
          ).subscribe();
  }

  private initVariablesInterval() {
    this.variables$ = interval(2 * 1000).pipe(
      mergeMap(() => {
        return this.playerClient.getVariables()
          .pipe(map((variables) => {
            let variablesChanged = false;
            const storageVariables = this.variableStorageService.getVariables();
            for (let sv of storageVariables) {
              const variableChanged = variables.find((v) => v.name === sv.name && v.value !== sv.value);
              if (variableChanged) {
                sv.value = variableChanged.value;
                variablesChanged = true;
              }
            }

            if (variablesChanged) {
              this.variableStorageService.setVariables(storageVariables);
            }

            return variables;
          }));
      })
    );
  }

  private confirmError() {
    this.dialog
      .open(DeleteConfirmComponent, {
        data: {
          title: 'errorConfirm.title',
          content: 'errorConfirm.content',
          confirm: 'errorConfirm.confirm'
        },
      }).afterClosed().subscribe((dialogResult) => {
        if (dialogResult) {
          this.playerClient.confirm().subscribe();
        }
      });
  }

  private textInputDialog(data: TextInputDto): void {
    const dialogRef = this.dialog.open(TextInputComponent, {
      data: data,
      disableClose: true
    });

    const dialogSubmitSubscription = dialogRef.componentInstance.submitClicked.subscribe((result) => {
        if (result) {
          data.value = result.text;
          this.playerClient.updateTextInputValue(data).subscribe();
        }
        dialogSubmitSubscription.unsubscribe();
      });
  }
}
