import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { LogDto, UserClient } from "../api/api.client";
import { take } from "rxjs";
import { UserService } from "./user.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: any) {
    if (error instanceof HttpErrorResponse) {
      // do nothing - http errors handled in HttpErrorService
      return;
    }
    console.error('Error from global error handler', error);
    const userClient = this.injector.get(UserClient);
    const userService = this.injector.get(UserService);
    const dto = {
      userId: userService.name$.getValue(),
      message: error?.toString()
    } as LogDto;
    userClient.postLog(dto).pipe(take(1)).subscribe();
  }
}