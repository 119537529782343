<br>

<!--
<mat-form-field *ngIf="webServices && webServices.length > 1">
  <mat-label>{{ 'categorySelect.select' | translate }}</mat-label>
  <mat-select (selectionChange)="onSelection($event)" [(value)]="model">
    <mat-option [value]="null">{{ 'projectSelect.manually' | translate }}</mat-option>
    <mat-option *ngFor="let webService of webServices" [value]="webService">
      {{ webService.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
-->

<ng-container *ngIf="currentWebService">
  <h3>{{ currentWebService.title }}</h3>

  <div [formGroup]="parametersForm">
    <table formArrayName="parameters">
      <ng-container *ngFor="let parameter of parameters.controls; let i = index">
      <tr [formGroupName]="i">
        <td class="value">
          <mat-form-field>
            <mat-label>{{ parameter.get('label')?.value }}</mat-label>
            <input matInput formControlName="value">
          </mat-form-field>
        </td>
      </tr>
      </ng-container>
    </table>
  </div>
  <button mat-flat-button color="primary" class="button" 
            (click)="getProject()">{{ this.currentWebService.buttonTitle }}</button>
</ng-container>

<br>
<br>

<h2 *ngIf="chooseProjectResult?.status === NOT_FOUND" class="warn">
  {{ 'projectSelect.notFound' | translate }}
</h2>

<h2 *ngIf="chooseProjectResult?.status === NOT_EXECUTABLE" class="warn">
  {{ 'projectSelect.notExecutable' | translate: { 
    name: chooseProjectResult?.name ?? '', version: chooseProjectResult?.version ?? '' 
  } }}
</h2>

<ng-container *ngIf="chooseProjectResult">
  <h4>{{ chooseProjectResult.name }} {{ chooseProjectResult.version }}</h4>

  <app-correction-values *ngIf="chooseProjectResult.correctionValues?.length" 
        [correctionValues]="chooseProjectResult.correctionValues"></app-correction-values>
</ng-container>

<ng-container *ngIf="currentWebService === null">
  <app-project-select></app-project-select>
</ng-container>
