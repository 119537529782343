import { Observable, map, of } from 'rxjs';
import { AuthService } from '../service/auth.service';
import { inject } from '@angular/core';
import { Router } from '@angular/router';

export const authGuard = (): Observable<boolean> => {
    const router = inject(Router);
    const authService = inject(AuthService);

    if (!authService.storage.isLoggedIn()) {
        return of(router.navigate(['/auth/login'])).pipe(
            map(() => false)
        );
    }

    return of(true);
}


