import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageGalleryComponent } from './image-gallery.component';
import { MatButtonModule } from '@angular/material/button';
import { SlideshowModule } from 'ng-simple-slideshow';

@NgModule({
  declarations: [
    ImageGalleryComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    SlideshowModule
  ],
  exports: [
    ImageGalleryComponent
  ]
})
export class ImageGalleryModule { }
