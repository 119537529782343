import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TextInputDto } from '../api/api.client';

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  private hubConnection: signalR.HubConnection;

  private isStarted = false;

  progressStepChanged$: Subject<void>;
  messagesChanged$: Subject<void>;
  textInputRequest$: Subject<TextInputDto>;

  constructor() {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${environment.apiRoot}/playerHub`)
      .withAutomaticReconnect()
      .build();
  }

  startConnection():void {
    if (this.isStarted) {
      return;
    }
    console.log('start SignalR Connection');
    this.isStarted = true;

    this.progressStepChanged$ = new Subject();
    this.messagesChanged$ = new Subject();
    this.textInputRequest$ = new Subject();

    this.hubConnection
      .start()
      .then(() => {
        console.log('Connection established with SignalR hub');
      })
      .catch((error) => {
        console.error('Error connecting to SignalR hub:', error);
      });

    this.hubConnection.on('ProgressStepChanged', () => {
      console.log('SignalR - ProgressStepChanged');
      this.progressStepChanged$.next();
    });

    this.hubConnection.on('MessagesChanged', () => {
      console.log('SignalR - MessagesChanged');
      this.messagesChanged$.next();
    });

    this.hubConnection.on('TextInputRequest', (data: TextInputDto) => {
      console.log('SignalR - TextInputRequest', data);
      this.textInputRequest$.next(data);
    });
  }

  sendMessage(message: string): void {
    this.hubConnection.invoke('SendMessage', message);
  }

  disconnect() {
    console.log('disconnect signalR');
    this.progressStepChanged$.complete();
    this.messagesChanged$.complete();
    this.hubConnection.off('ProgressStepChanged');
    this.hubConnection.off('MessagesChanged');
    this.hubConnection.off('TextInputRequest');
    this.hubConnection.stop();
    this.isStarted = false;
  }
}