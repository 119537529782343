import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

export enum Language {
  en = 'en',
  de = 'de'
}

@Component({
  selector: 'app-language-toggle',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './language-toggle.component.html',
  styleUrls: ['./language-toggle.component.scss']
})
export class LanguageToggleComponent {
  @Input() selectedLanguage$!: Observable<string>;
  @Output() switchLanguage = new EventEmitter<string>();

  toggleLanguage(lang: string) {
    this.switchLanguage.emit(lang);
  }
}
