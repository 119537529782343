import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from '../lib/guard';
import { ProfilePageModule } from './profile-page/profile-page.module';
import { AuthModule } from './auth-pages/auth.module';
import { TestingFlowModule } from './testing-flow-page/testing-flow.module';
import { FavoritesPageModule } from './crud-pages/favorites-page/favorites-page.module';
import { ProjectsPageModule } from './crud-pages/projects-page/projects-page.module';
import { ReportsPageModule } from './crud-pages/reports-page/reports-page.module';
import { LibrariesPageModule } from './crud-pages/libraries-page/libraries-page.module';
import { TestPlantsPageModule } from './crud-pages/test-plants-page/test-plants-page.module';
import { HardwareConnectorsPageModule } from './crud-pages/hardware-connectors-page/hardware-connectors-page.module';
import { UsersPageModule } from './crud-pages/users-page/users-page.module';
import { CategoriesPageModule } from './crud-pages/categories-page/categories-page.module';
import { WebServicesPageModule } from './crud-pages/webservices-page/webservices-page.module';
import { HomePageModule } from './home-page/home-page.module';
import { CHECKLIST_TYPE, HARDWARE_TYPE } from '../lib/constants';

const routes: Routes = [
  // Auth routes for guests
  {
    path: 'auth',
    loadChildren: () => AuthModule,
  },

  // Routes for authenticated users
  {
    path: '',
    canActivate: [authGuard],
    children: [
        {path: 'home', loadChildren: () => HomePageModule},
        {path: 'profile', loadChildren: () => ProfilePageModule},
        {path: 'favorites', loadChildren: () => FavoritesPageModule},
        {path: 'testing', loadChildren: () => TestingFlowModule, data: { categoryType: HARDWARE_TYPE } },
        {path: 'checklist', loadChildren: () => TestingFlowModule, data: { categoryType: CHECKLIST_TYPE } },
        {path: 'projects', loadChildren: () => ProjectsPageModule},
        {path: 'reports', loadChildren: () => ReportsPageModule},
        {path: 'libraries', loadChildren: () => LibrariesPageModule},
        {path: 'users', loadChildren: () => UsersPageModule},
        {path: 'test-plants', loadChildren: () => TestPlantsPageModule},
        {path: 'hardware-connectors', loadChildren: () => HardwareConnectorsPageModule},
        {path: 'categories', loadChildren: () => CategoriesPageModule},
        {path: 'webservices', loadChildren: () => WebServicesPageModule},
    ]
  },

  { path: '**', redirectTo: '/auth/login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
