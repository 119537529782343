<div
  class="circle clickable"
  [class]="getStatusClass(aggregateStatus(hardwareStatus))"
  (click)="isOpen = !isOpen"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
></div>

<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayHasBackdrop]="true" 
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  (backdropClick)="isOpen = false"
>
  <ul class="list">
    <li *ngFor="let status of hardwareStatus"><div
        class="circle"
        [class]="getStatusClass(status.hardwareState)"></div> {{ status.name }}</li>
  </ul>
</ng-template>