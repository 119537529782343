<h2 mat-dialog-title>{{ data.text }}</h2>

<mat-dialog-content>
  <p>{{ data.userDefinedResultDescription }}</p>

  <form [formGroup]="form" (ngSubmit)="onSubmit()" id="form">
    <label id="radio-group-label"><b>{{ 'defineMessageDialog.result' | translate }}</b></label>
    <mat-radio-group 
      class="radio-group"
      aria-labelledby="radio-group-label"
      formControlName="messageResult" 
      required>
      <mat-radio-button [value]="SUCCESS" class="radio-button">{{ 'defineMessageDialog.success' | translate }}</mat-radio-button>
      <mat-radio-button [value]="FAILURE" class="radio-button">{{ 'defineMessageDialog.failure' | translate }}</mat-radio-button>
    </mat-radio-group>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-stroked-button (click)="onStop()">{{ 'defineMessageDialog.cancel' | translate }}</button>
  <button form="form" type="submit" mat-flat-button color="primary" [disabled]="!form.valid">
    <mat-icon>check</mat-icon> {{ 'defineMessageDialog.confirm' | translate }}
  </button>
</mat-dialog-actions>
