import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, map } from 'rxjs';
import { OverwriteReportComponent } from 'src/app/atom/overwrite-report/overwrite-report.component';
import { CategoryDto, FileDto, HeaderItemDto, ReportClient, StoreReportDto, StoreReportResult, WebServiceConfigurationDto } from 'src/app/lib/api/api.client';
import { ReportService } from 'src/app/lib/service/report.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-report-page',
  templateUrl: './report-page.component.html',
  styleUrls: ['./report-page.component.scss'],
})
export class ReportPageComponent {
  @Input() category: CategoryDto | undefined;
  @Input() webServices: WebServiceConfigurationDto[] | undefined;

  form = new FormGroup({});
  fields: FormlyFieldConfig[] = [];
  model = {};

  headerItems$: Observable<HeaderItemDto[]> = this.reportClient.getHeaderItems()
  .pipe(
    map((headerItems: HeaderItemDto[]) => {
      this.fields = headerItems.map((headerItem: HeaderItemDto) => ({
        key: headerItem.name,
        type: this.reportService.getFieldType(headerItem.dataType),
        defaultValue: headerItem.value,
        props: {
          label: headerItem.label?.toUpperCase(),
          required: headerItem.isMandatory,
          readonly: true
        },
      }));
      return headerItems;
    })
  );

  constructor(
    private dialog: MatDialog,
    private snack: MatSnackBar,
    private translateService: TranslateService,
    private reportClient: ReportClient,
    private reportService: ReportService
  ) {}

  downloadPdfReport() {
    this.reportClient.downloadReport(this.reportService.userMessages$.getValue())
    .subscribe((report: FileDto) => {
      if (report.name && report.data) {
        const base64url = `data:application/pdf;base64,${report.data}`;
        saveAs(base64url, report.name);
      }
    });
  }

  downloadXmlReport() {
    this.reportClient.downloadReportAsXml(this.reportService.userMessages$.getValue())
    .subscribe((report: FileDto) => {
      if (report.name && report.data) {
        var blob = new Blob([atob(report.data)], {type: "application/xml"});
        saveAs(blob, report.name);
      }
    });
  }

  storeReport(webService: WebServiceConfigurationDto) {

    const dto = {
      ...webService,
      ...{
        userMessages: this.reportService.userMessages$.getValue()
      }
    } as StoreReportDto;

    this.reportClient.storeReport(dto).subscribe((result: StoreReportResult) => {
      switch (result) {
        case StoreReportResult._0: // success
          this.snack.open(this.translateService.instant('report.storeSuccess'));
          break;
        case StoreReportResult._1: // alreadyInDatabase
          this.showReportAlreadyInDatabaseDialog();
          break;
        default:
          throw new Error('not implemented');
      }
    });
  }

  emailReport() {
    this.reportClient.sendReportAsEmail(this.reportService.userMessages$.getValue()).subscribe((result: boolean) => {
      if (result) {
        this.snack.open(this.translateService.instant('category.report.sending'));
      } else {
        this.snack.open(this.translateService.instant('error.500'));
      }
    });
  }

  private showReportAlreadyInDatabaseDialog() {
    const dialogRef = this.dialog.open(OverwriteReportComponent);
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.reportClient.overwriteExistingReport(this.reportService.userMessages$.getValue()).subscribe();
      }
    });
  }
}
