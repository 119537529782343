import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { MatButtonModule } from '@angular/material/button';
import { UserClient, UserDto } from 'src/app/lib/api/api.client';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LanguageToggleComponent } from 'src/app/atom/language-toggle/language-toggle.component';
import { SettingsStorageService } from 'src/app/lib/service/settings-storage.service';
import { UserService } from 'src/app/lib/service/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-profile-form',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule, 
    FormlyModule, 
    MatButtonModule,
    LanguageToggleComponent
  ],
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss']
})
export class ProfileFormComponent {
  language$ = this.settingsStorageService.language$;

  supportEmail = environment.supportEmail;

  form = new FormGroup({});
  model = {};
  fields: FormlyFieldConfig[] = [
    {
      key: 'id'
    },
    {
      key: 'company.id'
    },
    {
      key: 'company.name',
      type: 'input',
      props: {
        label: this.translateService.instant('company.name'),
        required: true,
      },
    },
    {
      key: 'firstName',
      type: 'input',
      props: {
        label: this.translateService.instant('user.firstName'),
        required: true,
      },
    },
    {
      key: 'lastName',
      type: 'input',
      props: {
        label: this.translateService.instant('user.lastName'),
        required: true,
      },
    },
    {
      key: 'email',
      type: 'input',
      props: {
        label: this.translateService.instant('user.email'),
        readonly: true
      },
    },
    {
      key: 'roleName',
      type: 'input',
      props: {
        label: this.translateService.instant('user.roleName'),
        readonly: true
      },
    },
    {
      key: 'zoomFactor',
      type: 'input',
      defaultValue: 100,
      props: {
        type: 'number',
        label: this.translateService.instant('user.zoomFactor'),
        required: false,
        max: 150,
        min: 75
      },
    },
  ];

  constructor(
    private userClient: UserClient,
    private translateService: TranslateService,
    private settingsStorageService: SettingsStorageService,
    private userService: UserService,
    private snack: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.userClient.getProfile().subscribe((dto: UserDto) => {
      this.model = dto;
    });
  }

  onLanguageChange(language: string) {
    this.userService.setLanguage(language);
  }

  onSubmit() {
    this.userClient.postProfile(this.form.value).subscribe((user: UserDto) => {
      this.snack.open(this.translateService.instant('form.submitSuccess'));
      if (user.zoomFactor) {
        this.settingsStorageService.setZoomFactor(user.zoomFactor.toString());
      }
      this.userService.setName(`${user.firstName} ${user.lastName}`);
    });
  }
}
