import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { FormlyModule } from '@ngx-formly/core';
import { FileEventTarget } from '../message/message.component';

export interface ImportWordData {
  fileEvent: FileEventTarget;
};

@Component({
  selector: 'app-import-word',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    MaterialFileInputModule,
    MatFormFieldModule,
    MatDialogModule, 
    MatButtonModule,
    MatIconModule,
    FormlyModule,
  ],
  templateUrl: './import-word.component.html',
  styleUrls: ['./import-word.component.scss']
})
export class ImportWordComponent {
  model = {};

  form: FormGroup = this.fb.group({
    fileEvent: [null, Validators.required]
  });

  constructor(
    private fb: FormBuilder, 
    private dialogRef: MatDialogRef<ImportWordComponent>,
    @Inject(MAT_DIALOG_DATA) data: { wordData: ImportWordData }
  ) {
    if (data.wordData) {
      this.form.controls['fileEvent'].setValue(data.wordData.fileEvent);
    }
  }

  onDismiss() {
    this.dialogRef.close();
  }

  onConfirm() {
    this.dialogRef.close(this.form.value);
  }
}
