import { autoserializeAs } from 'cerializr';
import { Column } from 'src/app/lib/components/table/column';

export class UserMessage {
  @autoserializeAs(String)
  @Column({
    canSort: true,
  })
  text: string | undefined;
  @autoserializeAs(Number)
  @Column({
    canSort: true,
    translatePath: 'severity'
  })
  severity: Number | undefined;
}
