import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Report } from './report.model';
import { BehaviorSubject, Observable, Subject, map, switchMap } from 'rxjs';
import { DeserializeArray, JsonArray } from 'cerializr';
import { TableComponent } from 'src/app/lib/components/table/table.component';
import { MatButtonModule } from '@angular/material/button';
import { Router, RouterModule } from '@angular/router';
import { CompanyClient, ReportConfigurationDto } from 'src/app/lib/api/api.client';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { DeleteConfirmComponent } from 'src/app/atom/delete-confirm/delete-confirm.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-reports-list',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    TableComponent,
    MatButtonModule,
    RouterModule,
    MatDialogModule,
  ],
  templateUrl: './reports-list.component.html',
  styleUrls: ['./reports-list.component.scss'],
})
export class ReportsListComponent {
  private notification$: Subject<null> = new BehaviorSubject<null>(null);

  private originalReports: ReportConfigurationDto[];

  reports$: Observable<Report[]> = this.companyClient
    .getReportConfigurations().pipe(map((res) => {
      this.originalReports = res;
      return DeserializeArray(res as JsonArray, Report);
    }));

  data$ = this.notification$.pipe(switchMap(() => this.reports$));

  constructor(
    private router: Router,
    private companyClient: CompanyClient,
    public dialog: MatDialog
  ) {}

  onDelete(entry: Report) {
    const dialogRef = this.dialog.open(DeleteConfirmComponent);
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.companyClient.deleteReportConfiguration(entry).subscribe(() => {
          this.notification$.next(null);
        });
      }
    });
  }

  onEdit(entry: Report) {
    const item = this.originalReports.find((item: ReportConfigurationDto) => item.id === entry.id);
    this.router.navigate(['/reports/edit'], { state: item });
  }
}
