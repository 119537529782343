import { Component } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { CategoryDto, CompanyClient, FavoriteDto, TestPlantDto } from 'src/app/lib/api/api.client';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs';
import { FileInputComponent } from 'src/app/molecules/file-input/file-input.component';

@Component({
  selector: 'app-favorites-form',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormlyModule,
    MatButtonModule,
    MatFormFieldModule,
    FileInputComponent
  ],
  templateUrl: './favorites-form.component.html',
  styleUrls: ['./favorites-form.component.scss'],
})
export class FavoritesFormComponent {
  form = new FormGroup({});
  model = {} as FavoriteDto;
  fields: FormlyFieldConfig[] = [
    {
      key: 'id'
    },
    {
      key: 'name',
      type: 'input',
      props: {
        label: this.translateService.instant('testPlant.name'),
        required: true,
      },
    },
    {
      key: 'categoryId',
      type: 'select',
      props: {
        label: this.translateService.instant('testPlant.categoryName'),
        required: true,
        options: this.getCategories(),
      },
    },
    {
      key: 'testPlantId',
      type: 'select',
      props: {
        label: this.translateService.instant('hardwareConnector.testPlantName'),
        required: true,
        options: this.getTestPlants(),
      },
    },
  ];

  constructor(
    private router: Router, 
    private location: Location, 
    private companyClient: CompanyClient,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.model = this.location.getState() as FavoriteDto;
  }

  async onSubmit() {
    if (!this.form.valid) {
      return;
    }

    const dto = <FavoriteDto>{
      ...this.form.value,
    };

    this.companyClient.postFavorite(dto).subscribe(() => {
      this.router.navigate(["/favorites"]);
    });
  }

  private getCategories(): any {
    const data = this.companyClient.getCategories().pipe(
      map((items: CategoryDto[])=> {
        return items.map((item: CategoryDto) => {
          return { value: item.id, label: item.name };
        });
      })
    );
    return data;
  }

  private getTestPlants(): any {
    const data = this.companyClient.getTestPlants().pipe(
      map((items: TestPlantDto[])=> {
        return items.map((item: TestPlantDto) => {
          return { value: item.id, label: item.name };
        });
      })
    );
    return data;
  }
}
