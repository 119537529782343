import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { tableSymbol } from './column';
import { ColumnModel } from './column.model';
import { TableModel } from './table.model';
import { sortBy, orderBy, cloneDeep } from 'lodash';
import { MatSortModule, Sort, SortDirection } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-table',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatTableModule,
    MatSortModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule
  ],
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
  @Output() onDelete = new EventEmitter<any>();
  @Output() onEdit = new EventEmitter<any>();

  @Input() entity = '';

  private _data!: any[];
  private _originalData: any[] = [];
  private _tableModel!: TableModel;

  @Input() set data(values: any[] | null | undefined) {
    if (values) {
      this._data = cloneDeep(values);
      if (!this._data.length) {
        return;
      }
      this._tableModel = this._data[0][tableSymbol];
      this.buildColumns();
      if (!this._originalData.length) {
        // Keep original order of data
        this._originalData = cloneDeep(this._data);
      }
    }
  }
  get data(): any[] {
    return this._data;
  }

  columns!: ColumnModel[];
  displayedColumns!: Array<string | undefined>;

  constructor() {}

  ngOnInit() {}

  sortData(params: Sort) {
    const direction: SortDirection = params.direction;
    this.data = direction
      ? orderBy(this.data, [params.active], [direction])
      : this._originalData;
  }

  deleteClicked(element: any) {
    this.onDelete.emit(element);
  }

  editClicked(element: any) {
    this.onEdit.emit(element);
  }

  private buildColumns() {
    this.columns = this._tableModel.columns.filter((column: ColumnModel) => column.hide === false);
    this.sortColumns();
    this.displayedColumns = [...this.columns.map((col) => col.key), 'action'];
  }

  private sortColumns() {
    this.columns = sortBy(this.columns, ['order']);
  }
}
