import { autoserializeAs } from 'cerializr';
import { Column } from 'src/app/lib/components/table/column';

export class User {
  @autoserializeAs(String)
  id: string | undefined;
  @autoserializeAs(String)
  @Column({
    canSort: true,
  })
  firstName: string | undefined;
  @autoserializeAs(String)
  @Column({
    canSort: true,
  })
  lastName: string | undefined;
  @autoserializeAs(String)
  @Column({
    canSort: true,
  })
  email: string | undefined;
  @autoserializeAs(String)
  @Column({
    canSort: true,
  })
  roleName: string | undefined;
}
