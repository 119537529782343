import { Component } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { CompanyClient, ReportConfigurationDto } from 'src/app/lib/api/api.client';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FileInputComponent } from 'src/app/molecules/file-input/file-input.component';

@Component({
  selector: 'app-reports-form',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormlyModule,
    MatButtonModule,
    MatFormFieldModule,
    FileInputComponent
  ],
  templateUrl: './reports-form.component.html',
  styleUrls: ['./reports-form.component.scss'],
})
export class ReportsFormComponent {
  form = new FormGroup({});
  model = {} as ReportConfigurationDto;
  fields: FormlyFieldConfig[] = [
    {
      key: 'id'
    },
    {
      key: 'name',
      type: 'input',
      hide: true,
    },
    {
      key: 'version',
      type: 'input',
      hide: true,
    },
  ];

  constructor(
    private router: Router, 
    private location: Location, 
    private companyClient: CompanyClient) 
  {}

  ngOnInit(): void {
    this.model = this.location.getState() as ReportConfigurationDto;
    this.form.addControl('fileData', new FormControl(this.model.fileData, Validators.required));
  }

  async onSubmit() {
    const dto = <ReportConfigurationDto>{
      ...this.form.value,
    };
    this.companyClient.postReportConfiguration(dto).subscribe(() => {
      this.router.navigate(["/reports"]);
    });
  }

  updateFileData(fileData: string) {
    this.form.patchValue({fileData: fileData})
  }
}
